import { DialogConfig } from "@/core/types";
import { useDialog } from "./useDialog";
import { TermsAndConditions } from "./termsAndConditions";

export const useDialogTermsAndConditions = () => {
  const { openDialog } = useDialog();

  const openDialogTermsAndConditions = ({
    showFooter,
    onClose,
    onSave
  }: Omit<DialogConfig, "component">) =>
    openDialog({
      styles: {
        width: ["auto", "700px"],
        padding: "15px"
      },
      isModalSheet: false,
      showFooter,
      closeText: "Refuza",
      submitText: "Accepta",
      title: "Termeni și condiții - HeroDoc online SRL",
      onClose,
      onSave,
      component: <TermsAndConditions />
    });

  return openDialogTermsAndConditions;
};
