import { Redirect, Route } from "react-router";
import React, { Suspense } from "react";
import { IonContent, IonProgressBar, IonRouterOutlet } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth/hooks";
import AppRoute from "./AppRoute";

const PaymentPage = React.lazy(() => import("../pages/payment"));
const DashboardPage = React.lazy(() => import("../pages/dashboard"));
const AppointmentPage = React.lazy(() => import("../pages/appointment"));
const RegistrationPage = React.lazy(() => import("../pages/registration"));
const LoginPage = React.lazy(() => import("../pages/auth"));
const DoctorsPage = React.lazy(() => import("../pages/doctors"));
const SpecialityPage = React.lazy(() => import("../pages/speciality"));
const SpecialitiesPage = React.lazy(() => import("../pages/specialities"));
const HomePage = React.lazy(() => import("../pages/home"));
const DoctorPage = React.lazy(() => import("../pages/doctor"));
const ConfirmPhonePage = React.lazy(() => import("../pages/confirmPhone"));
const ChangePasswordPage = React.lazy(() => import("../pages/ChangePassword"));

const ChangePasswordRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <ChangePasswordPage />
  </Suspense>
);

const HomeRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <HomePage />
  </Suspense>
);

const SpecialitiesRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <SpecialitiesPage />
  </Suspense>
);

const SpecialityRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <SpecialityPage />
  </Suspense>
);

const DoctorsRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DoctorsPage />
  </Suspense>
);

const DoctorRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DoctorPage />
  </Suspense>
);

const LoginRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <LoginPage />
  </Suspense>
);

const RegistrationRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <IonContent>
      <RegistrationPage />
    </IonContent>
  </Suspense>
);

const AppointmentRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <AppointmentPage />
  </Suspense>
);

const DashboardRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <DashboardPage />
  </Suspense>
);

const ConfirmPhoneRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <ConfirmPhonePage />
  </Suspense>
);

const PaymentRoute = () => (
  <Suspense fallback={<IonProgressBar type="indeterminate" />}>
    <PaymentPage />
  </Suspense>
);

const Routes = () => {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuth();

  return (
    <IonRouterOutlet id="mainMenu">
      <Route exact path="/">
        <Redirect exact to={`/${i18n.language}`} />
      </Route>
      <Route exact path="/:locale">
        <Redirect exact to={`/${i18n.language}/home`} />
      </Route>
      <Route exact path="/payment">
        <Redirect exact to={`/${i18n.language}/payment`} />
      </Route>

      <AppRoute exact path="/:locale/home">
        <HomeRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/appointment">
        {isAuthenticated ? <AppointmentRoute /> : <LoginRoute />}
      </AppRoute>
      <AppRoute path="/:locale/dashboard">
        {isAuthenticated ? <DashboardRoute /> : <LoginRoute />}
      </AppRoute>
      <AppRoute exact path="/:locale/specialities">
        <SpecialitiesRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/specialities/:specialityId">
        <SpecialityRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/doctors">
        <DoctorsRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/doctors/:doctorId">
        <DoctorRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/login">
        {isAuthenticated ? <HomeRoute /> : <LoginRoute />}
      </AppRoute>
      <AppRoute exact path="/:locale/registration">
        {isAuthenticated ? <HomeRoute /> : <RegistrationRoute />}
      </AppRoute>
      <AppRoute exact path="/:locale/confirm-phone">
        {isAuthenticated ? <ConfirmPhoneRoute /> : <LoginRoute />}
      </AppRoute>
      <AppRoute exact path="/:locale/change-password">
        <ChangePasswordRoute />
      </AppRoute>
      <AppRoute exact path="/:locale/payment">
        <PaymentRoute />
      </AppRoute>
    </IonRouterOutlet>
  );
};

export default React.memo(Routes);
