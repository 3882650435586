import { getStyledElement, getMediaStyles } from "@/helpers";
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle
} from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { Grid } from "../grid";

const Card = getStyledElement(IonCard)({
  styles: ({ theme }) => {
    return {
      margin: "0",
      borderRadius: "0",
      boxShadow: "none",
      ...getMediaStyles({
        fontSize: ["36px", "48px"],
        borderBottomLeftRadius: [theme.borderRadius[20], "0"],
        borderBottomRightRadius: [theme.borderRadius[20], "0"]
      })
    };
  }
});

const Content = getStyledElement(IonCardContent)({
  styles: ({ theme, borderColor }) => ({
    borderRadius: theme.borderRadius[20],
    border: `1px solid ${(borderColor as string) || theme.colors.whiteBluePrx}`
  })
});

export const CardTitle = getStyledElement(IonCardTitle)({
  styles: ({ theme }) => ({
    fontWeight: "600",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    color: theme.colors.darkPrx,
    ...getMediaStyles({
      whiteSpace: ["break-spaces", "nowrap"],
      fontSize: ["32px", "48px"],
      display: [, "flex"],
      flexDirection: [, "row"],
      alignItems: [, "center"],
      gap: [, "10px"]
    })
  })
});

export const CardSubtitle = getStyledElement(IonCardSubtitle)({
  styles: ({ theme }) => ({
    fontWeight: 600,
    textTransform: "unset",
    color: theme.colors.darkPrx,
    ...getMediaStyles({
      fontSize: ["24px", "36px"]
    })
  })
});

export const OnlineLabel = getStyledElement("span")({
  styles: ({ theme }) => ({
    borderRadius: "30px",
    position: "relative",
    minWidth: "fit-content",
    width: "max-content",
    overflow: "hidden",
    ...getMediaStyles({
      display: [, "block"],
      backgroundColor: ["unset", theme.colors.secondaryPrx],
      color: ["unset", theme.colors.lightPrx],
      padding: ["0", "0 10px"],
      "&::after": [
        "",
        {
          content: "''",
          position: "absolute",
          top: 0,
          width: "150%",
          height: "100%",
          animation: "slide 2s ease-in-out infinite",
          background: `linear-gradient(
              to right, 
              rgba(255,255,255,0) 0%,
              rgba(255,255,255,0.4) 50%,
              rgba(128,186,232,0) 99%,
              rgba(125,185,232,0) 100%)`,
          "@keyframes slide": {
            from: { transform: "translateX(-200%)" },
            to: { transform: "translateX(40%)" }
          }
        }
      ]
    })
  })
});

export const CardSubtitleSmall = getStyledElement(IonCardSubtitle)({
  styles: ({ theme }) => ({
    fontWeight: 600,
    padding: "10px 0",
    margin: 0,
    textTransform: "unset",
    color: theme.colors.darkPrx,
    fontSize: theme.fontSize[16]
  })
});

const CartGrid = getStyledElement(Grid)({
  styles: () => ({
    "--ion-grid-width-sm": "100%"
  })
});

type CardMainProps = {
  backgroundColor?: string;
  borderColor?: string;
  minHeight?: string[] | string;
  flex?: string;
};

export const CardMain: FC<CardMainProps & PropsWithChildren> = ({
  children,
  backgroundColor,
  borderColor,
  flex = 0,
  minHeight = ["auto", "550px"]
}) => {
  return (
    <CartGrid p="0" flex={flex} fixed>
      <Card
        minHeight={minHeight}
        padding={["5px", "0"]}
        display="flex"
        backgroundColor={[borderColor, "unset"]}
      >
        <Content
          flex="1"
          display="flex"
          backgroundColor={backgroundColor}
          borderColor={backgroundColor}
        >
          {children}
        </Content>
      </Card>
    </CartGrid>
  );
};
