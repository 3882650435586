import { dayjs, setYupLocale } from "@/helpers";
import { PropsWithChildren, useEffect } from "react";
import { AppStorage } from "@/services/storage";
import { LANGUAGE_KEY } from "@/core/constants";
import { useRouteParams, useRouter } from "@/hooks";
import { useTranslation } from "react-i18next";
import { IonPage } from "@ionic/react";

export const LocaleLayout: React.FC<PropsWithChildren & { path?: any }> = ({
  children,
  path
}) => {
  const { locale } = useRouteParams();
  const { i18n } = useTranslation();
  const router = useRouter();

  const setLanguage = async (): Promise<void> => {
    i18n.changeLanguage(locale);
    dayjs.locale(locale);
    AppStorage.set(LANGUAGE_KEY, locale);
    setYupLocale(i18n.t);
  };

  useEffect(() => {
    if (locale && router.routeInfo.pathname === path) setLanguage();
  }, [locale]);

  return <IonPage>{children}</IonPage>;
};
