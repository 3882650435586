import { Box, Icon, SelectAccountActions, Span } from "@/components";
import { IonSelectOption } from "@ionic/react";
import { person } from "ionicons/icons";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { useLogout, useMedia, useRouter } from "@/hooks";
import { useTranslation } from "react-i18next";
import { Chip } from "../chip";

export const DashboardChip: React.FC = () => {
  const router = useRouter();
  const location = useLocation();
  const { isMobile } = useMedia();
  const logout = useLogout();
  const { t } = useTranslation();

  const dahsboardUrl = useMemo(
    () => (isMobile ? "dashboard" : "dashboard/appointments"),
    [isMobile]
  );

  const changeNavigation = async (event: CustomEvent<{ value: string }>) => {
    if (event.detail.value === location.pathname) {
      return;
    }

    if (event.detail.value === "logout") {
      await logout();

      return;
    }

    router.push(event.detail.value);
  };

  return (
    <Chip>
      <SelectAccountActions
        value={location.pathname}
        onIonChange={changeNavigation}
        interface="popover"
        selectedText="Logout"
      >
        <Box slot="label" variant="flexRow" alignItems="center">
          <Icon color={["primary", "light"]} slot="start" icon={person} />
          <Span>{t("DASHBOARD")}</Span>
        </Box>
        <IonSelectOption value="logout">{t("LOGOUT")}</IonSelectOption>
        <IonSelectOption value={dahsboardUrl}>
          {t("MY_DASHBOARD")}
        </IonSelectOption>
      </SelectAccountActions>
    </Chip>
  );
};
