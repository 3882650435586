import { getStyledElement } from "@/helpers";
import { CheckboxCustomEvent, IonCheckbox } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { Span } from "../span";

const CustomCheckbox = getStyledElement(IonCheckbox)({
  styles: ({ theme }) => ({
    "--size": theme.fontSize[16],
    "--border-color": theme.colors.primaryPrx,
    "&.error": {
      "--border-color": theme.colors.red74Prx
    }
  })
});

type CheckboxProps = {
  value?: boolean;
  error?: string;
  touched?: boolean;
  name?: string;
  onChange?: (event: CheckboxCustomEvent) => void;
};

export const Checkbox: FC<PropsWithChildren<CheckboxProps>> = ({
  children,
  error,
  value,
  touched,
  name,
  onChange = () => {}
}) => {
  return (
    <CustomCheckbox
      checked={!!value}
      name={name}
      labelPlacement="end"
      onIonChange={(event: CheckboxCustomEvent) => {
        // eslint-disable-next-line no-param-reassign
        event.detail.value = event.detail.checked;
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.detail.checked;
        onChange(event);
      }}
      className={error && touched && "error"}
    >
      <Span fontSize="14px">{children}</Span>
    </CustomCheckbox>
  );
};
