import { useAuth } from "@/store/auth/hooks";
import { IonProgressBar } from "@ionic/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { AppStorage } from "@/services/storage";
import { MainHeader } from "@/layout/header";
import { Box, VideoDialog } from "@/components";
import { LANGUAGE_KEY } from "@/core/constants";
import i18n from "@/i18n";
import { useAppTheme } from "@/hooks";
import { VideoPage } from "@/pages/video";
import { DialogProvider } from "@/components/dialog/hooks/useDialog/DialogProvider";

export const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { setAuthFromStorage } = useAuth();
  const [isLoadedApp, setIsLoadedApp] = useState(false);
  const theme = useAppTheme();

  useEffect(() => {
    (async () => {
      /**
       * @deprecated need to search a place where will be posible to preset app configuration, and init some app data
       */
      await AppStorage.create();
      await setAuthFromStorage().unwrap();
      const lang = await AppStorage.get(LANGUAGE_KEY);
      i18n.changeLanguage(lang);
      setIsLoadedApp(true);
    })();
  }, []);

  if (!isLoadedApp) return <IonProgressBar type="indeterminate" />;

  return (
    <DialogProvider>
      <MainHeader />
      <VideoDialog>
        <VideoPage />
      </VideoDialog>
      <Box
        position="relative"
        height={`calc(100vh - ${theme.sizes.headerHeight})`}
      >
        {children}
      </Box>
    </DialogProvider>
  );
};
