import { Box, Button, Col, Grid, Img, Row, Span } from "@/components";
import { useDialogTermsAndConditions } from "@/components/dialog/hooks";
import { dayjs, getMediaStyles, getStyledElement } from "@/helpers";
import { useAppTheme, useRouter } from "@/hooks";
import { IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";

const Footer = getStyledElement(IonFooter)({
  styles: ({ theme }) => ({
    paddingTop: "20px",
    backgroundColor: theme.colors.grayF4Prx,
    position: "unset"
  })
});

const Connect = getStyledElement(Span)({
  styles: ({ theme }) => ({
    fontWeight: 800,
    fontSize: "32px",
    padding: "5px 0",
    textTransform: "uppercase",
    color: theme.colors.primaryPrx,
    ...getMediaStyles({
      fontSize: ["24px", "32px"]
    })
  })
});

const Address = getStyledElement("a")({
  styles: ({ theme }) => ({
    color: theme.colors.primaryPrx,
    textDecoration: "none",
    padding: "5px 0",
    ...getMediaStyles({
      fontSize: ["24px", "32px"]
    })
  })
});

const FooterBottomCol = getStyledElement(Col)({
  styles: () => ({
    display: "flex",
    alignItems: "center"
  })
});

const Cooperite = getStyledElement(Span)({
  styles: () => ({
    fontWeight: 600,
    ...getMediaStyles({
      fontSize: ["16px", "16px"]
    })
  })
});

const ItemButton = getStyledElement("button")({
  styles: ({ theme }) => ({
    fontWeight: 700,
    lineHeight: 1.5,
    color: theme.colors.primaryPrx,
    textAlign: "start",
    textDecoration: "underline",
    ...getMediaStyles({
      fontSize: ["16px", "16px"]
    })
  })
});

export const BaseFooter = () => {
  const theme = useAppTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const openDialogTermsAndConditions = useDialogTermsAndConditions();
  const year = dayjs().format("YYYY");

  return (
    <Footer>
      <Grid fixed py="0" px="15px">
        <Row mb="30px">
          <Col
            display="flex"
            flexDirection="column"
            alignItems={["center", "flex-start"]}
            size="12"
            size-md="8"
          >
            <Connect>{t("CONNECT")}</Connect>
            {/* <Address href="tel:+37362198852">+373 6219-8852</Address> */}
            <Address href="mailto: herodocinfo@gmail.com">
              herodocinfo@gmail.com
            </Address>
            <Address textAlign="center">
              Mun. Chișinău Str. Nicolae Dimo 5/3
            </Address>
          </Col>
          <Col
            size="12"
            size-md="4"
            display="flex"
            alignItems={["center", "flex-end"]}
            justifyContent={["center", "flex-end"]}
          >
            <Box variant="flexCol" alignItems={["center", "flex-end"]}>
              <Button
                width="max-content"
                onClick={() => router.push("appointment")}
              >
                {t("CONSULT_SPECIALIST")}
              </Button>
              <Span fontSize="18px" mt="20px" fontWeight="600">
                {t("MEDICAL_APPOINTMENTS_ONLINE")}
              </Span>
            </Box>
          </Col>
        </Row>

        <Row
          p={["10px", "40px"]}
          bg={theme.colors.whiteBluePrx}
          borderRadius={`${theme.borderRadius[20]} ${theme.borderRadius[20]} 0 0`}
          mx={["-5px", "0"]}
        >
          <FooterBottomCol size="12" size-md="4">
            <Cooperite>© HeroDoc online SRL {year}</Cooperite>
          </FooterBottomCol>
          <FooterBottomCol size="6" size-md="4">
            <Box variant="flexCol">
              <ItemButton
                type="button"
                onClick={() => router.push("specialities")}
                mb="5px"
              >
                {t("SPECIALITIES")}
              </ItemButton>
              <ItemButton
                type="button"
                onClick={() => router.push("doctors")}
                mb="5px"
              >
                {t("DOCTORS")}
              </ItemButton>
              <ItemButton
                type="button"
                onClick={() => router.push("appointment")}
                mb="5px"
              >
                {t("MAKE_APPOINTMENT")}
              </ItemButton>
              <ItemButton
                type="button"
                onClick={() =>
                  openDialogTermsAndConditions({ showFooter: false })
                }
              >
                {t("PRIVACY_POLICY")}
              </ItemButton>
            </Box>
          </FooterBottomCol>
          <FooterBottomCol
            py="15px"
            size="6"
            size-md="4"
            justifyContent="flex-end"
          >
            <Box variant="flexRow">
              <a href="https://www.facebook.com/herodocmoldova">
                <Img
                  height={["40px", "80px"]}
                  src="assets/icon/facebookSocial.svg"
                />
              </a>
            </Box>
          </FooterBottomCol>
        </Row>
      </Grid>
    </Footer>
  );
};
