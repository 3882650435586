import { BaseQueryFn, retry } from "@reduxjs/toolkit/dist/query";
import type { AxiosRequestConfig } from "axios";

import { SerializedError } from "@reduxjs/toolkit";
import { AxiosClient } from "../api";

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
  },
  unknown,
  SerializedError
> = async ({ url, method, data, params }) => {
  try {
    const result = await AxiosClient.request({
      url,
      method,
      data,
      params
    });

    return { data: result };
  } catch (axiosError) {
    const error = axiosError as SerializedError;

    return { error };
  }
};

export const baseQueryWithRetry = retry(axiosBaseQuery, { maxRetries: 6 });
