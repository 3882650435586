import { useEffect, useMemo, useState } from "react";
import { LocalVideoTrack } from "twilio-video";
import { getStyledElement } from "@/helpers";
import { Box, Span, Select } from "@/components";
import { AppStorage } from "@/services/storage";
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY
} from "../../constants";
import VideoTrack from "../VideoTrack";
import useDevices from "../../hooks/useDevices";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack";
import useVideoContext from "../../hooks/useVideoContext";

const Preview = getStyledElement("div")({
  styles: () => ({
    width: "300px",
    maxHeight: "200px",
    margin: "0.5em auto",
    "& video": {
      maxHeight: "200px"
    }
  })
});

export default function VideoInputList() {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(
    (track) => track.kind === "video"
  ) as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState("");
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    AppStorage.set(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId }
    });
  }

  const options = useMemo(
    () =>
      videoInputDevices.map(({ deviceId, label }) => ({
        value: deviceId,
        label
      })),
    [videoInputDevices]
  );

  useEffect(() => {
    const setVideoInputState = async () => {
      const videoInputId = await AppStorage.get(SELECTED_VIDEO_INPUT_KEY);

      setStoredLocalVideoDeviceId(videoInputId);
    };

    setVideoInputState();
  }, []);

  return (
    <Box>
      {localVideoTrack && (
        <Preview>
          <VideoTrack isLocal track={localVideoTrack} />
        </Preview>
      )}
      {videoInputDevices.length > 1 ? (
        <Select
          value={localVideoInputDeviceId || ""}
          onIonChange={(e: CustomEvent) =>
            replaceTrack(e.detail.value as string)
          }
          options={options}
        >
          Video Input
        </Select>
      ) : (
        <>
          <Span>Video Input</Span>
          <Span>
            {localVideoTrack?.mediaStreamTrack.label || "No Local Video"}
          </Span>
        </>
      )}
    </Box>
  );
}
