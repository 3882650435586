import React from "react";
import { useTranslation } from "react-i18next";

import { Gender } from "@/core/constants";
import { SelectN } from "../select/SelectN";

type SelectGenderProps = {
  touched: boolean;
  error?: string;
  id: string;
};

export const SelectGender: React.FC<SelectGenderProps> = ({
  touched,
  error,
  ...field
}) => {
  const { t } = useTranslation();

  const options = Object.values(Gender).map((gender) => ({
    value: gender,
    label: t(gender)
  }));

  return (
    <SelectN
      {...field}
      touched={touched}
      error={error}
      options={options}
      label={t("INPUTS.GENDER")}
    />
  );
};
