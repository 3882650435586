import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { InputCustomN } from "../input";
import { Box } from "../box";
import { Dialog } from "../dialog";
import { ScheduleCalendar } from "./ScheduleCalendar";

type DateButtonProps = {
  name?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  onChange?: (e: any) => void;
  doctorId: number;
  label: string;
  disabled?: boolean;
};

export const SelectScheduleDate: React.FC<DateButtonProps> = ({
  value,
  name,
  error,
  touched,
  label,
  onChange = () => {},
  doctorId,
  disabled = false
}) => {
  const input = useRef<HTMLInputElement>(null);

  const [formatedDate, setFormatedDate] = useState("");
  const [date, setDate] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  const dispatchInputEvent = () => {
    if (!input.current) return;

    const event = new Event("input", { bubbles: true });
    // eslint-disable-next-line no-underscore-dangle
    const tracker = (input.current as any)._valueTracker;

    if (tracker) {
      tracker.setValue(date);
    }

    input.current.dispatchEvent(event);
  };

  const onSelectDate = (selectedDate: string) => {
    const dayjsDate = dayjs(selectedDate);
    const formated = dayjsDate.format("DD/MM/YYYY HH:mm");
    const isoDate = dayjsDate.toISOString();

    setDate(isoDate);
    setFormatedDate(formated);

    setIsOpenModal(false);
  };

  const fillDefaultValues = () => {
    const dayjsDate = dayjs(value);
    const formated = value ? dayjsDate.format("DD/MM/YYYY HH:mm") : "";

    setDate(value || "");
    setFormatedDate(formated);
  };

  useEffect(() => {
    dispatchInputEvent();
  }, [date]);

  useEffect(() => {
    if (value !== date) fillDefaultValues();
  }, [value]);

  return (
    <Box>
      <input
        type="hidden"
        ref={input}
        value={date}
        name={name}
        onInput={onChange}
      />
      <InputCustomN
        className={`${error && touched ? "ion-invalid" : ""} ${
          touched ? "ion-touched" : ""
        }`}
        type="text"
        value={formatedDate}
        readonly
        label={label}
        labelPlacement="floating"
        fill="outline"
        placeholder="__/__/____"
        errorText={error}
        name={name}
        disabled={disabled}
        onClick={() => setIsOpenModal(true)}
      />
      <Dialog
        isOpen={isOpenModal}
        title=""
        isModalSheet
        showFooter={false}
        onDismiss={() => setIsOpenModal(false)}
      >
        <Box variant="flexRow" justifyContent="center" mt="15px">
          <ScheduleCalendar doctorId={doctorId} handleDismiss={onSelectDate} />
        </Box>
      </Dialog>
    </Box>
  );
};
