import { FC, useState } from "react";
import { getCurrency, getStyledElement } from "@/helpers";
import dayjs from "dayjs";
import { checkmarkCircleOutline } from "ionicons/icons";
import { Api, AxiosClient } from "@/api";
import { AppStorage } from "@/services/storage";
import { TRANSACTION_KEY } from "@/core/constants";
import { HttpError } from "@/core/types";
import { useTranslation } from "react-i18next";
import { Appointment } from "@/store/appointments/types";
import { useShowHttpError } from "@/hooks/useShowHttpError";
import { useDialogTermsAndConditions } from "../useDialogTermsAndConditions";
import { Box } from "../../../../box";
import { Span } from "../../../../span";
import { P } from "../../../../p";
import { Img } from "../../../../img";
import { Checkbox } from "../../../../checkbox";
import { Button } from "../../../../button";
import { Icon } from "../../../../icon";

const Item = getStyledElement(Box)({
  styles: () => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 0"
  })
});

const ItemLabel = getStyledElement(Span)({
  styles: () => ({
    color: "#797979",
    flex: "1",
    fontWeight: 500
  })
});

const LinkButton = getStyledElement("button")({
  styles: ({ theme }) => ({
    fontWeight: 700,
    color: theme.colors.primaryPrx,
    fontSize: "14px",
    textAlign: "start",
    textDecoration: "underline"
  })
});

interface ConfirmPaymentDialogProps {
  appointment: Appointment;
}

export const ConfirmPaymentDialog: FC<ConfirmPaymentDialogProps> = ({
  appointment
}) => {
  const showHttpError = useShowHttpError();
  const { t } = useTranslation();
  const [isAcceptedTandC, setIsAcceptedTandC] = useState(false);
  const openDialogTermsAndConditions = useDialogTermsAndConditions();

  const pay = async () => {
    try {
      const { redirectUrl, transactionId } = await AxiosClient.post<{
        redirectUrl: string;
        transactionId: string;
      }>(Api.payment(), {
        appointmentId: appointment.id,
        description: `Consultatie la medicul ${appointment.speciality} ${
          appointment.doctor.user.firstName
        } ${appointment.doctor.user.lastName} la data de ${dayjs(
          appointment.date
        ).format("DD.MM.YYYY HH:mm")}`
      });

      await AppStorage.set(TRANSACTION_KEY, transactionId);

      window.location.href = redirectUrl;
    } catch (error) {
      const { message } = error as HttpError;

      showHttpError(message);
    }
  };

  return (
    <Box p="15px">
      <Item>
        <ItemLabel>{t("CONFIRM_PAYMENT.MERCHANT")}</ItemLabel>
        <Span flex="1" textAlign="end">
          HeroDoc online SRL
        </Span>
      </Item>
      <Item>
        <ItemLabel>{t("CONFIRM_PAYMENT.SPECIALITY")}</ItemLabel>
        <Span flex="1" textAlign="end">
          {t(`SPECIALITIES_LIST.${appointment.speciality}.NAME`)}
        </Span>
      </Item>
      <Item>
        <ItemLabel>{t("CONFIRM_PAYMENT.DOCTOR")}</ItemLabel>
        <Span flex="1" textAlign="end">
          {appointment.doctor.user.firstName} {appointment.doctor.user.lastName}
        </Span>
      </Item>
      <Item>
        <ItemLabel>{t("CONFIRM_PAYMENT.AMOUNT")}</ItemLabel>
        <Span flex="1" textAlign="end">
          {getCurrency(appointment.doctor.price)}
        </Span>
      </Item>
      <Item>
        <ItemLabel>{t("CONFIRM_PAYMENT.DATE")}</ItemLabel>
        <Span flex="1" textAlign="end">
          {dayjs(appointment.date).format("DD.MM.YYYY HH:mm")}
        </Span>
      </Item>
      <P fontSize="20px" fontWeight="500">
        {t("CONFIRM_PAYMENT.PAYMENT_METHODS")}
      </P>
      <Box variant="flexRow" gridGap="20px" mt="20px">
        <Img height="50px" src="assets/icon/mc.svg" />
        <Img height="50px" src="assets/icon/visaLogo.jpg" />
      </Box>
      <Box
        color="#797979"
        p="40px 0 20px"
        variant="flexRow"
        gridGap="5px"
        flexWrap="wrap"
      >
        <Checkbox
          value={isAcceptedTandC}
          onChange={(event) => {
            setIsAcceptedTandC(event.detail.checked);
          }}
        >
          <Span>{t("CONFIRM_PAYMENT.PRIVACY_POLICY_CHECKBOX")}</Span>
        </Checkbox>
        <LinkButton
          type="button"
          onClick={() =>
            openDialogTermsAndConditions({
              showFooter: false
            })
          }
        >
          {t("PRIVACY_POLICY")}
        </LinkButton>
      </Box>
      <Item alignItems="flex-end">
        <Box variant="flexRow" flex="1">
          <Box variant="flexCol">
            <ItemLabel color="#797979" mb="5px">
              {t("CONFIRM_PAYMENT.TOTAL_PRICE")}
            </ItemLabel>
            <Span fontSize="20px" fontWeight="600">
              {getCurrency(appointment.doctor.price)}
            </Span>
          </Box>
          <Box
            variant="flexRow"
            alignItems="flex-end"
            ml="20px"
            padding="5px 0"
          >
            <Img src="assets/icon/maib.png" />
          </Box>
        </Box>

        <Box variant="flexRow" justifyContent="flex-end">
          <Button type="button" onClick={pay} disabled={!isAcceptedTandC}>
            <Icon icon={checkmarkCircleOutline} slot="start" />
            {t("CONFIRM_PAYMENT.PAYMENT")}
          </Button>
        </Box>
      </Item>
    </Box>
  );
};
