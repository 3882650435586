import { IonContent, IonPage } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const Content = styled(IonContent)``;

export const VideoLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <IonPage>
      <Content>{children}</Content>
    </IonPage>
  );
};
