import { getStyledElement, getMediaStyles } from "@/helpers";
import { GlobalThemeProps } from "@/theme";
import { IonButton, IonFabButton } from "@ionic/react";
import styled from "styled-components";
import { space } from "styled-system";

export const Button = getStyledElement(IonButton)({
  styles: ({ theme }) => ({
    "--border-radius": theme.sizes.buttonBorderRadius,
    "--box-shadow": "none",
    "text-transform": "initial",
    height: "max-content",
    margin: 0,
    "&::part(native)": {
      ...getMediaStyles({
        "--padding-top": [theme.padding[10], theme.padding[12]],
        "--padding-bottom": [theme.padding[10], theme.padding[12]],
        "--padding-start": [theme.padding[15], theme.padding[20]],
        "--padding-end": [theme.padding[15], theme.padding[20]]
      })
    }
  }),
  variants: {
    buttonWhiteShadow: {
      "--box-shadow": "0px 0px 4px 5px rgba(227,227,227,0.3)",
      width: "max-content",
      fontWeight: 600
    },
    ternary: (theme) => ({
      "--background": "none",
      maxWidth: "min-content",
      fontWeight: 600,
      color: theme.colors.lightPrx,
      boxShadow: `0px 3px 4px 0px ${theme.colors.grayC1Prx}`,
      "&:active": {
        boxShadow: "0px -4px 4px 0px rgba(0,0,0, 0.1) inset"
      },
      transitionProperty: "box-shadow, transform",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      borderRadius: theme.sizes.buttonBorderRadius,
      backgroundColor: theme.colors.primaryPrx
    }),
    fullWidth: {
      position: "relative",
      display: "block",
      backgroundColor: "unset",
      width: "100%",
      margin: 0,
      height: "150px",
      "&::part(native)": {
        "--padding-top": 0,
        "--padding-bottom": 0,
        "--padding-start": 0,
        "--padding-end": 0,
        borderRadius: "0",
        backgroundColor: "unset"
      }
    },
    action: (theme) => ({
      "--background": theme.colors.whitePrx,
      "&::part(native)": {
        "--padding-top": "7px",
        "--padding-bottom": "7px",
        "--padding-start": "7px",
        "--padding-end": "7px",
        "--border-radius": "50%",
        border: `2px solid ${theme.colors.primaryPrx}`
      }
    }),
    round: (theme) => ({
      width: "min-content",
      position: "relative",
      backgroundColor: "unset",
      borderRadius: "50%",
      boxShadow: `0px 2px 4px 0px ${theme.colors.grayC1Prx}`,
      padding: 0,
      marginInline: 0,
      "&::part(native)": {
        "--padding-top": 0,
        "--padding-bottom": 0,
        "--padding-start": 0,
        "--padding-end": 0,
        "--border-radius": "50%",
        backgroundColor: "unset"
      },
      ...getMediaStyles({
        height: theme.sizes.doctorListImageHeight
      })
    })
  }
});

export const ButtonTransparent = styled(IonButton)`
  --border-radius: ${({ theme }: GlobalThemeProps) =>
    theme.sizes.buttonBorderRadius};
  --box-shadow: none;
  --background: none;
  --color: unset;
  --padding-start: 0;
  --padding-end: 0;
  display: inline-flex;
  text-transform: initial;
  ${space}
`;

export const ButtonShadow = getStyledElement(IonButton)({
  styles: ({ theme }) => ({
    height: "unset",
    "--border-radius": theme.borderRadius[20],
    "--background": theme.colors.whitePrx,
    "--color": theme.colors.primaryPrx,
    textTransform: "initial"
  })
});

export const ButtonAppointment = getStyledElement(ButtonShadow)({
  styles: ({ theme, color, backgroundColor, padding }) => ({
    "--border-radius": theme.borderRadius[30],
    backgroundColor: "inherit",
    padding: 0,
    "&::part(native)": {
      backgroundColor: (backgroundColor as string) || theme.colors.lightPrx,
      color: (color as string) || theme.colors.primaryPrx,
      "--padding-start": (padding as string) || theme.padding[15],
      "--padding-end": (padding as string) || theme.padding[15],
      "--padding-top": (padding as string) || theme.padding[15],
      "--padding-bottom": (padding as string) || theme.padding[15]
    }
  })
});

export const ButtonCircle = getStyledElement(IonFabButton)({
  styles: () => ({
    height: "25px",
    width: "25px",
    "--box-shadow": "none"
  })
});

export const ButtonListItem = getStyledElement(IonButton)({
  styles: () => ({
    "text-transform": "none"
  }),
  variants: {
    circle: {
      "--border-radius": "50%",
      height: "45px",
      width: "45px"
    }
  }
});
