import { Dayjs } from "dayjs";
import { FC } from "react";
import { Box } from "../box";

export const CalendarWeekDays: FC<{ now: Dayjs }> = ({ now }) => {
  const dateFormat = "dd";

  return (
    <Box variant="flexRow">
      {[0, 1, 2, 3, 4, 5, 6].map((_, index) => (
        <Box
          variant="flexRow"
          flex="1 1 50px"
          height="50px"
          justifyContent="center"
          alignItems="center"
          key={`weeks-${index}`}
        >
          {now.weekday(index).format(dateFormat)}
        </Box>
      ))}
    </Box>
  );
};
