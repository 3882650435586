import DeviceSelectionDialog from "@/pages/video/components/DeviceSelectionDialog";
import { ButtonListItem, Icon, Span } from "@/components";
import { settings } from "ionicons/icons";

export default function SettingsMenuButton() {
  return (
    <>
      <ButtonListItem color="medium" variant="circle" id="open-settings-modal">
        <Span slot="icon-only">
          <Icon fontSize="28px" color="light" icon={settings} />
        </Span>
      </ButtonListItem>
      <DeviceSelectionDialog trigger="open-settings-modal" />
    </>
  );
}
