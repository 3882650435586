import { Box, Button, Grid, Icon, Row } from "@/components";
import { grid as GridIcon, list as ListIcon } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { AppStorage } from "@/services/storage";
import { IS_DOCTOR_LIST_GRID } from "@/core/constants";

export interface ListOrGridLayoutProps {
  items: any[];
  selectedDefault: boolean;
  displayGridItem: (item: any) => React.ReactElement;
  displayListItem: (item: any) => React.ReactElement;
}

export const ListOrGridLayout: React.FC<ListOrGridLayoutProps> = (props) => {
  const { items, displayGridItem, displayListItem, selectedDefault } = props;
  const [isGrid, setIsGrid] = useState<boolean>(selectedDefault);

  const component = useCallback(
    (item: any) => (isGrid ? displayGridItem(item) : displayListItem(item)),
    [isGrid]
  );

  const switchGrid = () => {
    setIsGrid(!isGrid);
    AppStorage.set(IS_DOCTOR_LIST_GRID, !isGrid);
  };

  return (
    <Box variant="flexCol">
      <Button onClick={switchGrid} alignSelf="flex-end" mb="20px">
        <Icon icon={isGrid ? ListIcon : GridIcon} />
      </Button>
      <Grid fixed p="0">
        <Row mx={isGrid ? "-10px" : "initial"}>
          {items.map((item, index: number) => (
            <React.Fragment key={item?.id ?? index}>
              {component(item)}
            </React.Fragment>
          ))}
        </Row>
      </Grid>
    </Box>
  );
};
