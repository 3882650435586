import { IonItem, IonList, IonRadio, IonRadioGroup } from "@ionic/react";
import ReactCountryFlag from "react-country-flag";
import { FC, useEffect, useState } from "react";
import { getStyledElement } from "@/helpers";
import { Span } from "../span";
import { Box } from "../box";
import { Countries } from "./countries";
import { Country } from "./types";
import { InputSearch } from "../inputSearch";
import { Dialog } from "../dialog";

const CountryButton = getStyledElement("button")({
  styles: ({ theme }) => ({
    position: "absolute",
    width: "80px",
    top: "11px",
    left: "17px",
    zIndex: "3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: theme.fontSize[16]
  })
});

const List = getStyledElement(IonList)({
  styles: () => ({
    overflow: "auto"
  })
});

interface SelectCountryProps {
  onChange: (value: string) => void;
  country: Country;
  color: string;
}

export const SelectCountry: FC<SelectCountryProps> = ({
  onChange,
  country,
  color
}) => {
  const [filteredItems, setFilteredItems] = useState<Country[]>([...Countries]);
  const [countryPreffix, setCountryPreffix] = useState<string>(country.prefix);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const filterList = (searchQuery: string | null | undefined) => {
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...Countries]);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();

      setFilteredItems(
        Countries.filter((item) => {
          return item.name.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

  useEffect(() => {
    setCountryPreffix(country.prefix);
  }, [country]);

  return (
    <>
      <CountryButton type="button" onClick={() => setIsOpenModal(true)}>
        <ReactCountryFlag
          svg
          countryCode={country.code}
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "6px"
          }}
        />
        <Span pl="3px" pt="1px" color={color}>
          ({country.prefix})
        </Span>
      </CountryButton>
      <Dialog
        title="Selecteaza tara"
        isOpen={isOpenModal}
        onSave={() => {
          onChange(countryPreffix);
          setIsOpenModal(false);
        }}
        onClose={() => {
          setCountryPreffix(country.prefix);
          setIsOpenModal(false);
        }}
        isModalSheet={false}
      >
        <Box>
          <InputSearch onSearch={(ev) => filterList(ev.detail.value)} />
          <List mode="ios" lines="none">
            <IonRadioGroup
              name="country"
              onIonChange={(ev) => setCountryPreffix(ev.detail.value)}
              value={countryPreffix}
            >
              {filteredItems.map((countryItem) => (
                <IonItem mode="ios" key={countryItem.code}>
                  <IonRadio
                    mode="ios"
                    value={countryItem.prefix}
                    justify="space-between"
                  >
                    <Box variant="flexRow" alignItems="center">
                      <ReactCountryFlag
                        svg
                        countryCode={countryItem.code}
                        style={{
                          width: "25px",
                          height: "20px"
                        }}
                      />
                      <Span ml="10px">{countryItem.name}</Span>
                    </Box>
                  </IonRadio>
                </IonItem>
              ))}
            </IonRadioGroup>
          </List>
        </Box>
      </Dialog>
    </>
  );
};
