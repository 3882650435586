import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { IMaskMixin } from "react-imask";
import { getStyledElement } from "@/helpers";
import { useAppTheme } from "@/hooks";
import { InputCustomN } from "./components";
import { SelectCountry } from "./SelectCountry";
import { Countries } from "./countries";
import { Box } from "../box";

type InputPhoneProps = {
  value?: string;
  error?: string;
  touched?: boolean;
  name?: string;
  readonly?: boolean;
  id?: string;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  label: string;
};

const PhoneInputCustom = getStyledElement(InputCustomN)({
  styles: () => ({
    "&.input-fill-outline": {
      "--padding-start": "100px",
      ".input-bottom": {
        "--padding-start": "0"
      },
      ".input-outline-start": {
        "--padding-start": "16px"
      },
      "&.has-value.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md, &.has-focus.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md":
        { transform: " translateY(-35%) scale(0.8) translateX(-110px)" }
    }
  })
});

const RefInput = forwardRef((props, ref) => (
  <PhoneInputCustom ref={ref} {...props} />
));

const MaskInput = IMaskMixin<any, any>(({ inputRef, ...props }) => (
  <RefInput {...props} ref={inputRef as any} />
));

export const InputPhone: React.FC<InputPhoneProps> = ({
  value,
  error,
  onChange,
  onBlur,
  name,
  readonly,
  label,
  touched,
  id
}) => {
  const [phonePreffix, setPhonePreffix] = useState("");
  const [phoneSuffix, setPhoneSuffix] = useState("");
  const [phone, setPhone] = useState(value);
  const ref = useRef<HTMLInputElement>(null);
  const theme = useAppTheme();

  const selectedCountry = useMemo(
    () => Countries.find(({ prefix }) => prefix === phonePreffix) || null,
    [phonePreffix]
  );

  const dispatchInputEvent = () => {
    if (!ref.current) return;

    const event = new Event("input", { bubbles: true });
    // eslint-disable-next-line no-underscore-dangle
    const tracker = (ref.current as any)._valueTracker;

    if (tracker) {
      tracker.setValue(phone);
    }

    ref.current.dispatchEvent(event);
  };

  const fillDefaultValues = () => {
    if (!value) return;

    const country = Countries.find(({ prefix }) => value.startsWith(prefix));

    if (!country) return;

    setPhone(value);
    setPhonePreffix(country.prefix);
    setPhoneSuffix(value.replace(country.prefix, ""));
  };

  useEffect(() => {
    fillDefaultValues();
  }, []);

  useEffect(() => {
    dispatchInputEvent();
  }, [phone]);

  useEffect(() => {
    const suffix = phoneSuffix.trim().replaceAll("-", "");

    setPhone(`${phonePreffix}${suffix}`);
  }, [phonePreffix, phoneSuffix]);

  return (
    <>
      <input
        id={id}
        ref={ref}
        type="hidden"
        value={phone}
        name={name}
        onInput={onChange}
      />
      <Box position="relative">
        {selectedCountry && (
          <SelectCountry
            country={selectedCountry}
            color={
              error && touched ? theme.colors.red74Prx : theme.colors.primaryPrx
            }
            onChange={(preffix) => {
              setPhoneSuffix("");
              setPhonePreffix(preffix);
            }}
          />
        )}
        <MaskInput
          className={`${error && touched ? "ion-invalid" : ""} ${
            touched ? "ion-touched" : ""
          }`}
          name={name}
          type="tel"
          value={phoneSuffix}
          readonly={readonly}
          label={label}
          labelPlacement="floating"
          fill="outline"
          placeholder={selectedCountry?.mask}
          errorText={error}
          definitions={{ x: /[0-9]/ }}
          mask={selectedCountry?.mask || ""}
          onBlur={onBlur}
          onAccept={(val: string) => setPhoneSuffix(val)}
        />
      </Box>
    </>
  );
};
