import { H2, Icon } from "@/components";
import { Box } from "@/components/box";
import { getMediaStyles, getStyledElement } from "@/helpers";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/react";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { calendarOutline, personCircle, settingsOutline } from "ionicons/icons";
import { Roles } from "@/core/constants";
import { useTranslation } from "react-i18next";
import { useMedia, useRouter } from "@/hooks";
import i18n from "@/i18n";
import { useGetUser } from "@/store/user/hooks";
import { FaUserDoctor, FaUsers } from "react-icons/fa6";

const Nav = getStyledElement(Box)({
  styles: ({ theme }) => ({
    backgroundColor: theme.colors.whiteBluePrx,
    "--ion-item-background": theme.colors.whiteBluePrx,
    "--ion-item-color": theme.colors.primaryPrx,
    "--padding-start": theme.padding[15],
    ...getMediaStyles({
      borderRadius: ["0", theme.borderRadius[20]]
    }),
    alignItems: "center",
    padding: "25px 0",
    transition: "width 0.2s ease-out"
  })
});

const ItemIcon = getStyledElement(Icon)({
  styles: ({ theme }) => ({
    color: theme.colors.primaryPrx,
    "margin-inline-end": "15px"
  })
});

const Account = getStyledElement(Box)({
  styles: ({ theme }) => ({
    padding: `0 ${theme.padding[15]}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  })
});

const DoctorIcon = getStyledElement(FaUserDoctor)({
  styles: () => ({
    "margin-inline-end": "15px",
    width: "24px",
    height: "23px"
  })
});

const PacientIcon = getStyledElement(FaUsers)({
  styles: () => ({
    "margin-inline-end": "15px",
    width: "24px",
    height: "23px"
  })
});

const Label = getStyledElement(IonLabel)();

const Item = getStyledElement(IonItem)();

const Avatar = getStyledElement(Box)({
  styles: ({ theme }) => ({
    "border-top-left-radius": "50%",
    "border-top-right-radius": "50%",
    "border-bottom-left-radius": theme.borderRadius[15],
    "border-bottom-right-radius": theme.borderRadius[15],
    width: "200px",
    height: "160px",
    overflow: "hidden",
    "background-color": theme.colors.grayF4Prx
  })
});

export const Navigation: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useGetUser();
  const router = useRouter();
  const { t } = useTranslation();
  const { isMobile } = useMedia();
  const [showElement, setShowElement] = useState(false);

  const width = useMemo(() => {
    return router.routeInfo.pathname === `/${i18n.language}/dashboard`
      ? "100%"
      : "0";
  }, [router.routeInfo.pathname]);

  const navAnimation = useMemo(
    () => (isMobile ? "none" : "forward"),
    [isMobile]
  );

  useEffect(() => {
    const isDashboardRoute =
      router.routeInfo.pathname === `/${i18n.language}/dashboard`;
    let timer: NodeJS.Timeout | null = null;

    if (!isDashboardRoute) {
      timer = setTimeout(() => {
        setShowElement(true);
      }, 190);
    } else {
      setShowElement(false);

      if (timer) {
        clearTimeout(timer);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [router.routeInfo.pathname]);

  return (
    <>
      <Nav width={[width, "350px"]} variant="flexCol">
        <Box width="350px" variant="flexCol" alignItems="center">
          <Account>
            <Avatar>
              <Icon
                width="100%"
                height="100%"
                color="primary"
                icon={personCircle}
              />
            </Avatar>
            <H2>
              {user?.firstName} {user?.lastName}
            </H2>
          </Account>
          <IonList lines="none">
            <Item
              button
              onClick={() =>
                router.push("dashboard/appointments", navAnimation)
              }
            >
              <ItemIcon src={calendarOutline} />
              <Label>{t("DASHBOARD_PAGES.APPOINTMENTS")}</Label>
            </Item>
            {user?.role === Roles.Admin && (
              <>
                <Item
                  button
                  onClick={() => router.push("dashboard/doctors", navAnimation)}
                >
                  <DoctorIcon slot="start" />
                  <Label>{t("DASHBOARD_PAGES.ALL_DOCTORS")}</Label>
                </Item>
                <Item
                  button
                  onClick={() =>
                    router.push("dashboard/patients", navAnimation)
                  }
                >
                  <PacientIcon slot="start" />
                  <Label>{t("DASHBOARD_PAGES.ALL_PATIENTS")}</Label>
                </Item>
              </>
            )}
            <Item
              button
              onClick={() =>
                router.push("dashboard/personal-info", navAnimation)
              }
            >
              <ItemIcon slot="start" src="assets/icon/profile.svg" />
              <Label>{t("DASHBOARD_PAGES.PERSONAL_INFO")}</Label>
            </Item>
            {user?.role === Roles.Doctor && (
              <Item
                button
                onClick={() => router.push("dashboard/schedule", navAnimation)}
              >
                <ItemIcon slot="start" src="assets/icon/schedule.svg" />
                <Label>{t("DASHBOARD_PAGES.SCHEDULE")}</Label>
              </Item>
            )}
            <Item
              button
              onClick={() => router.push("dashboard/settings", navAnimation)}
            >
              <ItemIcon src={settingsOutline} />
              <Label>{t("DASHBOARD_PAGES.SETTINGS")}</Label>
            </Item>
          </IonList>
        </Box>
      </Nav>
      <IonContent id="main">
        <Box>{showElement && children}</Box>
      </IonContent>
    </>
  );
};
