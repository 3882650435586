import { IonInput, IonNote } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { InputCustom, Item, Label } from "./components";

type InputProps = {
  value?: string | number | null;
  error?: string;
  touched?: boolean;
  name?: string;
  readonly?: boolean;
  id?: string;
  inputRef?: any;
  type?:
    | "date"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "time"
    | undefined;
  placeholder?: string;
  onIonChange?: React.ComponentProps<typeof IonInput>["onIonChange"];
  handleChange?: any;
  handleBlur?: React.ComponentProps<typeof IonInput>["onBlur"];
  handleFocus?: React.ComponentProps<typeof IonInput>["onFocus"];
};

export const Input: FC<PropsWithChildren<InputProps>> = ({
  value = null,
  error,
  touched,
  handleChange,
  handleBlur,
  handleFocus,
  name,
  type,
  placeholder,
  children,
  onIonChange,
  readonly,
  inputRef,
  ...rest
}) => {
  return (
    <Item
      {...rest}
      className={`${!error && "ion-valid"} ${
        error && touched && "ion-invalid"
      } ${touched && "ion-touched"}`}
    >
      <Label position="stacked">{children}</Label>
      <InputCustom
        readonly={readonly}
        className={`${!error && "ion-valid"} ${
          error && touched && "ion-invalid"
        } ${touched && "ion-touched"}`}
        value={value}
        name={name}
        type={type}
        ref={inputRef}
        placeholder={placeholder}
        onIonChange={onIonChange}
        onInput={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <IonNote slot="error">{error}</IonNote>
    </Item>
  );
};
