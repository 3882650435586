import { IonSearchbar } from "@ionic/react";
import React from "react";
import { getStyledElement } from "@/helpers";

type InputSearchProps = {
  onSearch?: (event: CustomEvent) => void;
};

const Search = getStyledElement(IonSearchbar)({
  styles: ({ theme }) => ({
    "--background": "#ffffff80",
    "--border-radius": theme.borderRadius[30],
    "--color": theme.colors.primaryPrx,
    "--icon-color": theme.colors.primaryPrx,
    "--box-shadow": "none",
    padding: "0"
  })
});

export const InputSearch: React.FC<InputSearchProps> = ({ onSearch }) => {
  return <Search onIonInput={onSearch} />;
};
