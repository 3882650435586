import {
  ConfigureStoreOptions,
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import { loadingBarReducer } from "react-redux-loading-bar";
import { authSlice } from "./auth/reducer";
import { doctorsApi } from "./doctors/api";
import { appointmentsApi } from "./appointments/api";
import { userApi } from "./user/api";
import { pacientsApi } from "./pacients/api";
import { specialtiesApi } from "./specialties/api";

const rootReducer = combineReducers({
  authReducer: authSlice.reducer,
  [doctorsApi.reducerPath]: doctorsApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [pacientsApi.reducerPath]: pacientsApi.reducer,
  [specialtiesApi.reducerPath]: specialtiesApi.reducer,
  loadingBar: loadingBarReducer
});

export const setupStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        doctorsApi.middleware,
        appointmentsApi.middleware,
        userApi.middleware,
        pacientsApi.middleware,
        specialtiesApi.middleware
      ),
    ...options
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
