import { Route, RouteProps } from "react-router";
import React, { FC, ReactNode } from "react";
import { LocaleLayout } from "@/layout/content/localeLayout";

const AppRoute: FC<RouteProps> = ({ children, path, ...props }) => {
  return (
    <Route {...props} path={path}>
      <LocaleLayout path={(props as any).computedMatch.url}>
        {children as ReactNode}
      </LocaleLayout>
    </Route>
  );
};

export default React.memo(AppRoute);
