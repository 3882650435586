import { getStyledElement, getMediaStyles } from "@/helpers";
import { useAppTheme, useRouter } from "@/hooks";
import { arrowBackOutline } from "ionicons/icons";
import { FC, PropsWithChildren } from "react";
import { Box } from "../box";
import { H1 } from "../h";
import { Icon } from "../icon";

const ButtonBack = getStyledElement("button")();
const BackIcon = getStyledElement(Icon)({
  styles: ({ theme }) => ({
    color: theme.colors.primaryPrx
  })
});
const Title = getStyledElement(H1)({
  styles: () => ({
    margin: "0",
    ...getMediaStyles({
      fontSize: ["24px", "32px"]
    })
  })
});

export const DashboardPageHeader: FC<
  { showBack?: boolean } & PropsWithChildren
> = ({ showBack, children }) => {
  const router = useRouter();
  const theme = useAppTheme();

  const goBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [none, lang, dashboard, ...rest] =
      router.routeInfo.pathname.split("/");

    if (rest.length === 1) {
      router.push("dashboard", "none");

      return;
    }

    const restPath = rest.slice(0, -1).join("/");

    router.push(`${dashboard}/${restPath}`);
  };

  return (
    <Box
      variant="flexRow"
      justifyContent="space-between"
      alignItems="flex-start"
      mb="10px"
    >
      <ButtonBack
        mr="5px"
        display={!showBack ? ["block", "none"] : "block"}
        type="button"
        onClick={() => goBack()}
      >
        <BackIcon size="large" icon={arrowBackOutline} />
      </ButtonBack>
      <Title color={theme.colors.primaryPrx}>{children}</Title>
    </Box>
  );
};
