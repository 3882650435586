import { FC } from "react";
import { Img } from "../img";

export const DoctorImage: FC<
  {
    variant?: string;
    gender: string;
    imgUrl: string;
  } & React.HTMLAttributes<HTMLElement>
> = ({ variant, gender, imgUrl, ...props }) => {
  return (
    <Img
      {...props}
      variant={variant}
      src={imgUrl || `assets/doctor${gender}.png`}
      alt="doctor"
    />
  );
};
