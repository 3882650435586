import { IonSelectOption } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonSelect } from "../buttonSelect";

type SelectDoctorSpecialityPopoverProps = {
  onChange: (event: CustomEvent<{ value: string }>) => any;
  options: string[];
  isDisabled?: boolean;
  value?: string;
};

export const SelectDoctorSpecialityPopover: React.FC<
  SelectDoctorSpecialityPopoverProps
> = ({ onChange, isDisabled, value, options }) => {
  const { t } = useTranslation();
  return (
    <ButtonSelect
      placeholder="specialitatea"
      mode="ios"
      value={value}
      fontSize={["14px"]}
      disabled={isDisabled}
      onIonChange={onChange}
    >
      {options.map((option) => (
        <IonSelectOption value={option} key={option} color="primary">
          {t(`SPECIALITIES_LIST.${option}.NAME`)}
        </IonSelectOption>
      ))}
    </ButtonSelect>
  );
};
