import React from "react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack
} from "twilio-video";
import { getMediaStyles, getStyledElement } from "@/helpers";
import { Icon, Span } from "@/components";
import { personCircle } from "ionicons/icons";
import AudioLevelIndicator from "../AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting";

const Container = getStyledElement("div")({
  styles: () => ({
    isolation: "isolate",
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: 0,
    width: "250px",
    overflow: "hidden",
    borderRadius: "4px",
    border: "2px solid rgb(245, 248, 255)",
    paddingTop: `calc(${(9 / 16) * 100}% - 2px)`,
    background: "black",
    "& video": {
      "object-fit": "contain !important"
    },
    ...getMediaStyles({
      height: ["90px"],
      width: [`${(90 * 16) / 9}px`],
      fontSize: ["12px"],
      paddingTop: [`${90 - 2}px`]
    })
  })
});

const InfoContainer = getStyledElement("div")({
  styles: () => ({
    position: "absolute",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    background: "transparent",
    top: 0
  })
});

const InfoRowBottom = getStyledElement("div")({
  styles: () => ({
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0
  })
});

const Identity = getStyledElement("span")({
  styles: () => ({
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "0.18em 0.3em 0.18em 0",
    margin: 0,
    display: "flex",
    alignItems: "center"
  })
});

const InnerContainer = getStyledElement("span")({
  styles: () => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  })
});

const AvatarContainer = getStyledElement("span")({
  styles: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1
  })
});

const ReconnectingContainer = getStyledElement("span")({
  styles: () => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1
  })
});

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isLocalParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  children,
  isLocalParticipant
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <Container onClick={onClick}>
      <InfoContainer>
        <NetworkQualityLevel participant={participant} />
        <InfoRowBottom>
          <Identity>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Span>
              {participant.identity}
              {isLocalParticipant && " (You)"}
            </Span>
          </Identity>
        </InfoRowBottom>
      </InfoContainer>
      <InnerContainer>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <AvatarContainer>
            <Icon fontSize="80px" color="light" icon={personCircle} />
          </AvatarContainer>
        )}
        {isParticipantReconnecting && (
          <ReconnectingContainer>
            <Span>Reconnecting...</Span>
          </ReconnectingContainer>
        )}
        {children}
      </InnerContainer>
    </Container>
  );
}
