import { useTranslation } from "react-i18next";
import { Appointment } from "@/store/appointments/types";
import { ConfirmPaymentDialog } from "./confirmPayment";
import { useDialog } from "./useDialog";

export const useDialogPayment = () => {
  const { openDialog } = useDialog();
  const { t } = useTranslation();

  const openDialogPayment = (appointment: Appointment) =>
    openDialog({
      styles: {
        width: ["auto", "700px"],
        padding: "15px"
      },
      showFooter: false,
      title: t("CONFIRM_PAYMENT.TITLE"),
      component: <ConfirmPaymentDialog appointment={appointment} />
    });

  return openDialogPayment;
};
