import localeData from "dayjs/plugin/localeData";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import("dayjs/locale/en");
import("dayjs/locale/ru");
import("dayjs/locale/ro");

dayjs.extend(localeData);
dayjs.extend(weekdayPlugin);
dayjs.extend(objectPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.tz.setDefault("Europe/Chisinau");

const timezonedDayjs = (...args: any[]) => {
  return dayjs(...args).tz();
};

const timezonedUnix = (value: number) => {
  return dayjs.unix(value).tz();
};

const timezonedUtc = (...args: any[]) => {
  return dayjs.utc(...args).tz();
};

const timezonedLocale = (...args: any[]) => {
  return dayjs.locale(...args);
};

timezonedDayjs.unix = timezonedUnix;
timezonedDayjs.utc = timezonedUtc;
timezonedDayjs.locale = timezonedLocale;

export default timezonedDayjs;
