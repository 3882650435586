import { useEffect } from "react";
import { useUpdatePatientMutation } from "@/store/pacients/hooks";
import { useToast } from "./useToast";
import { useHttpErrorHandler } from "./useHttpErrorHandler";

export const useUpdatePatient = () => {
  const [updatePatient, { error, isSuccess }] = useUpdatePatientMutation();
  const { showSuccess } = useToast();

  useHttpErrorHandler(error);

  useEffect(() => {
    if (!isSuccess) return;

    showSuccess({ message: "TOASTS.SAVED_DATA" });
  }, [isSuccess]);

  return { updatePatient };
};
