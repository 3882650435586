import { IonSelectOption } from "@ionic/react";
import { SelectCustomN } from "./components";

type SelectValue = null | string | number | (string | number)[];

type SelectProps = {
  value?: SelectValue;
  multiple?: boolean;
  options?: { value: string | number | null; label: any }[];
  name?: string;
  onChange?: (event: CustomEvent<any>) => void;
  placeholder?: string;
  error?: string;
  touched?: boolean;
  label: string;
  disabled?: boolean;
};

export const SelectN: React.FC<SelectProps> = ({
  value,
  error,
  touched,
  name,
  options = [],
  multiple,
  onChange,
  label,
  disabled = false
}) => {
  return (
    <SelectCustomN
      label={label}
      labelPlacement="floating"
      fill="outline"
      name={name}
      value={value}
      multiple={multiple}
      onIonChange={onChange}
      disabled={disabled}
      className={`${error && touched ? "ion-invalid" : ""} ${
        touched ? "ion-touched" : ""
      }`}
    >
      {options.map((option) => (
        <IonSelectOption key={option.value} value={option.value}>
          {option.label}
        </IonSelectOption>
      ))}
    </SelectCustomN>
  );
};
