import { IonSplitPane } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { Navigation } from "../navigation";

export const DashboardLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <IonSplitPane contentId="main">
      <Navigation>{children}</Navigation>
    </IonSplitPane>
  );
};
