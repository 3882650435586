export const Api = {
  refresh() {
    return "/auth/refresh";
  },
  logout() {
    return "/auth/logout";
  },
  login() {
    return "/auth/login";
  },
  registration() {
    return "/auth/registration";
  },
  changePassword() {
    return "/auth/change-password";
  },
  me() {
    return "/auth/me";
  },
  meSchedule() {
    return `${this.me()}/schedule`;
  },
  initVerificationPhone() {
    return "/auth/init-verification-phone";
  },
  checkVerificatinPhone() {
    return "/auth/check-verification-code";
  },
  meAppointments() {
    return `${this.me()}/appointments`;
  },
  appointments() {
    return "/appointments";
  },
  appointment(id: number) {
    return `${this.appointments()}/${id}`;
  },
  doctors() {
    return "/doctors";
  },
  specialties() {
    return "/specialties";
  },
  users() {
    return "/users";
  },
  user(id: number) {
    return `${this.users()}/${id}`;
  },
  doctor(id: number) {
    return `${this.doctors()}/${id}`;
  },
  pacients() {
    return "/pacients";
  },
  pacient(id: number) {
    return `${this.pacients()}/${id}`;
  },
  doctorWorkingDays(id: number) {
    return `${this.doctor(id)}/working-days`;
  },
  doctorWorkingHours(id: number) {
    return `${this.doctor(id)}/working-hours`;
  },
  appointmentFinish(id: number) {
    return `${this.appointments()}/${id}/finish`;
  },
  payment() {
    return "/payment";
  },
  paymentRefund() {
    return `${this.payment()}/refund`;
  },
  paymentStatus() {
    return `${this.payment()}/status`;
  },
  paymentCommit(id: number) {
    return `${this.payment()}/${id}/commit`;
  },
  videoCredentials() {
    return "/video";
  },
  finishPayment() {
    return `${this.payment()}/finish`;
  }
};
