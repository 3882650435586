import { Box } from "@/components";
import { IonContent } from "@ionic/react";
import { FC, PropsWithChildren } from "react";
import { BaseFooter } from "../../footer";

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <IonContent>
      <Box variant="flexCol" height="100%">
        {children}
        <BaseFooter />
      </Box>
    </IonContent>
  );
};
