import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Pacient } from "@/core/types";
import { axiosBaseQuery } from "../baseQuary";

export const pacientsApi = createApi({
  reducerPath: "Pacients",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Pacients"],
  endpoints: (build) => ({
    getPacients: build.query<Pacient[], void>({
      query: () => ({
        url: Api.pacients(),
        method: "get"
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: "Pacients" as const, id })),
              { type: "Pacients", id: "LIST" }
            ]
          : [{ type: "Pacients", id: "LIST" }];
      }
    }),
    getPatient: build.query<Pacient, { id: number }>({
      query: ({ id }) => ({
        url: Api.pacient(id),
        method: "get"
      }),
      providesTags: (_, __, { id }) => [{ type: "Pacients", id }]
    }),
    updatePatient: build.mutation<void, Partial<Pacient> & { id: number }>({
      query: (patient) => ({
        url: Api.pacient(patient.id),
        method: "PUT",
        data: patient
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Pacients", id: "LIST" },
        { type: "Pacients", id }
      ]
    }),
    deletePacient: build.mutation<void, number>({
      query: (id) => ({
        url: Api.user(id),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, id) => [
        { type: "Pacients", id: "LIST" },
        { type: "Pacients", id }
      ]
    })
  })
});
