import { useEffect } from "react";
import { useDeletePacientMutation } from "@/store/pacients/hooks";
import { useToast } from "./useToast";
import { useHttpErrorHandler } from "./useHttpErrorHandler";

export const useDeletePatient = () => {
  const [deletePacient, { error, isSuccess }] = useDeletePacientMutation();
  const { showSuccess } = useToast();

  useHttpErrorHandler(error);

  useEffect(() => {
    if (!isSuccess) return;

    showSuccess({ message: "TOASTS.DOCTOR_REMOVE" });
  }, [isSuccess]);

  return { deletePacient };
};
