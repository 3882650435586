import { useAuth } from "@/store/auth/hooks";
import { useResetUserState } from "@/store/user/hooks";
import { HttpError } from "@/core/types";
import { useResetAppointmentsState } from "@/store/appointments/hooks";
import { useDispatch } from "react-redux";
import { pacientsApi } from "@/store/pacients/api";
import { doctorsApi } from "@/store/doctors/api";
import { useShowHttpError } from "./useShowHttpError";

export const useLogout = () => {
  const { logout } = useAuth();
  const resetUserState = useResetUserState();
  const resetAppointmentsState = useResetAppointmentsState();
  const showHttpError = useShowHttpError();
  const dispatch = useDispatch();

  return async () => {
    try {
      await logout().unwrap();
      resetUserState();
      resetAppointmentsState();
      dispatch(pacientsApi.util.resetApiState());
      dispatch(doctorsApi.util.resetApiState());
    } catch (error) {
      const errorMessage = error as HttpError;

      showHttpError(errorMessage.message);
    }
  };
};
