import { AxiosClient, Api } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tokens } from "@/core/types";
import { LoginData } from "../types";

export const login = createAsyncThunk(
  "auth/login",
  (user: LoginData): Promise<Tokens> =>
    AxiosClient.post<Tokens>(Api.login(), user)
);
