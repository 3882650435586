import { DialogContext } from "@/contexts";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { DialogConfig, DialogProps, DialogStackItem } from "@/core/types";
import { Dialog } from "@/components";

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stack, setStack] = useState<DialogStackItem[]>([]);

  const openDialog = useCallback(
    (config: DialogConfig) => {
      const oppenedDialogs = stack.map((dialog) => ({
        ...dialog,
        isOpen: false
      }));

      setStack([...oppenedDialogs, { ...config, isOpen: true }]);
    },
    [stack]
  );

  const currentDialogConfig = useMemo<DialogConfig>(
    () =>
      stack.at(stack.length - 1) || {
        component: null,
        isOpen: false
      },
    [stack]
  );

  const currentDialogProps = useMemo<DialogProps>(() => {
    const { component, ...props } = currentDialogConfig;

    return props;
  }, [currentDialogConfig]);

  const closeStackItem = () => {
    let newStack = [];

    if (stack.length === 1) {
      newStack = [{ ...stack[0], isOpen: false }];
    } else {
      newStack = stack.reduce<DialogStackItem[]>((acc, curr, index) => {
        if (index !== stack.length - 1) {
          acc.push({ ...curr, isOpen: index === stack.length - 2 });
        }

        return acc;
      }, []);
    }

    setStack(newStack);
  };

  const onClose = async () => {
    if (currentDialogConfig?.onClose) {
      await currentDialogConfig?.onClose();
    }

    closeStackItem();
  };

  const onSave = async () => {
    if (currentDialogConfig?.onSave) {
      await currentDialogConfig?.onSave();
    }

    closeStackItem();
  };

  const onDismiss = async () => {
    if (currentDialogConfig?.onDismiss) {
      await currentDialogConfig?.onDismiss();
    }

    setStack([]);
  };

  const providerContext = useMemo(
    () => ({
      openDialog,
      onDismiss,
      onSave,
      onClose
    }),
    [openDialog, onDismiss, onSave, onClose]
  );

  return (
    <DialogContext.Provider value={providerContext}>
      <Dialog
        {...currentDialogProps}
        onClose={onClose}
        onSave={onSave}
        onDismiss={onDismiss}
      >
        {stack.map((item, index) => (
          <div
            key={`dialog-${index}`}
            hidden={!item.isOpen && stack.length !== 1}
          >
            {item.component}
          </div>
        ))}
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};
