import { FC, PropsWithChildren, useMemo, useRef } from "react";
import { createAnimation, IonModal, IonContent } from "@ionic/react";
import { getStyledElement } from "@/helpers";
import { useRouteQueryParams } from "@/hooks";
import { useAuth } from "@/store/auth/hooks";

const Modal = getStyledElement(IonModal)({
  styles: () => ({
    "--height": "100%",
    "--width": "100%"
  })
});

export const VideoDialog: FC<PropsWithChildren> = ({ children }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { roomId } = useRouteQueryParams();
  const { isAuthenticated } = useAuth();
  const isOpen = useMemo(
    () => !!(isAuthenticated && roomId),
    [roomId, isAuthenticated]
  );

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" }
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  return (
    <Modal
      isOpen={isOpen}
      ref={modal}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
    >
      <IonContent>{children}</IonContent>
    </Modal>
  );
};
