import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenu,
  IonContent,
  IonMenuButton,
  IonList,
  IonItem,
  IonMenuToggle
} from "@ionic/react";
import { Grid, Icon, Span } from "@/components";
import { getStyledElement, getMediaStyles } from "@/helpers";
import { useMedia, useRouter } from "@/hooks";
import i18n from "@/i18n";
import { useTranslation } from "react-i18next";
import { ProfileChip } from "../profileChip";

const Header = getStyledElement(IonHeader)({
  styles: ({ theme }) => ({
    boxShadow: "none",
    ...getMediaStyles({
      background: [theme.colors.secondaryPrx, theme.colors.lightPrx]
    }),
    "&.header-md::after": {
      content: "unset"
    }
  })
});

const Title = getStyledElement("button")({
  styles: () => ({
    display: "flex",
    alignSelf: "center"
  })
});

const Toolbar = getStyledElement(IonToolbar)({
  styles: () => ({
    "--background": "none",
    "--border-style": "none",
    "--padding-top": "10px"
  })
});

const Hamburger = getStyledElement(IonMenuButton)({
  styles: ({ theme }) => ({
    ...getMediaStyles({
      display: ["block", "none"]
    }),
    "--color": theme.colors.darkPrx,
    "&::part(icon)": {
      width: "42px",
      height: "42px"
    }
  })
});

const ButtonNav = getStyledElement(IonButton)({
  styles: ({ theme }) => ({
    "text-transform": "none",
    margin: "0 15px !important",
    "font-weight": "600 !important",
    "--color": theme.colors.darkPrx,
    ...getMediaStyles({
      display: ["none", "block"]
    }),
    "&::part(native)": {
      fontSize: "16px"
    }
  })
});

const Buttons = getStyledElement(IonButtons)({
  styles: () => ({
    margin: 0
  })
});

export const MainHeader: React.FC = () => {
  const router = useRouter();
  const { isMobile } = useMedia();
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <Grid>
          <Toolbar px={[0, "15px"]}>
            <Title
              slot="start"
              type="button"
              onClick={() => router.push("home")}
            >
              <Icon
                height="30px"
                width="100px"
                src={
                  isMobile ? "assets/icon/logoBlue.svg" : "assets/icon/logo.svg"
                }
                alt="herodoc"
              />
            </Title>
            <Buttons slot="end">
              <ButtonNav routerLink={`/${i18n.language}/doctors`} type="button">
                {t("DOCTORS")}
              </ButtonNav>
              <ButtonNav
                type="button"
                routerLink={`/${i18n.language}/specialities`}
              >
                {t("SPECIALITIES")}
              </ButtonNav>
              {/* <Box mr="15px">
                <LanguageToggle />
              </Box> */}
              <ProfileChip />
              <Hamburger />
            </Buttons>
          </Toolbar>
        </Grid>
      </Header>
      <IonMenu contentId="mainMenu">
        <IonContent>
          <IonList lines="none">
            <IonMenuToggle>
              <IonItem routerLink={`/${i18n.language}/doctors`} type="button">
                <Span fontWeight="600">{t("DOCTORS")}</Span>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle>
              <IonItem
                type="button"
                routerLink={`/${i18n.language}/specialities`}
              >
                <Span fontWeight="600">{t("SPECIALITIES")}</Span>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};
