import { FC, PropsWithChildren } from "react";
import AppStateProvider, { useAppState } from "./state";
import ErrorDialog from "./components/ErrorDialog";
import "./types";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning";
import { useConnectionOptions } from "./hooks";
import { RoomPage } from "./pages";
import { ParticipantProvider } from "./components/participantProvider";
import { ChatProvider } from "./components/chatProvider";
import { VideoProvider } from "./components/videoProvider";

const VideoLayout: FC<PropsWithChildren> = ({ children }) => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>{children}</ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const VideoPage = () => {
  return (
    <UnsupportedBrowserWarning>
      <AppStateProvider>
        <VideoLayout>
          <RoomPage />
        </VideoLayout>
      </AppStateProvider>
    </UnsupportedBrowserWarning>
  );
};
