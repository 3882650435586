import { Box, Button, Col, Grid, H1, Row, Spinner } from "@/components";
import { useAppTheme } from "@/hooks";
import { useEffect } from "react";
import { useIonRouter } from "@ionic/react";
import LocalVideoPreview from "../LocalVideoPreview";
import { useAppState } from "../../state";
import useVideoContext from "../../hooks/useVideoContext";
import ToggleAudioButton from "../ToggleAudioButton";
import ToggleVideoButton from "../ToggleVideoButton";
import SettingsMenuButton from "../SettingsMenuButton";

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
}

export default function PreJoinScreen({
  name,
  roomName
}: DeviceSelectionScreenProps) {
  const router = useIonRouter();
  const theme = useAppTheme();
  const { getToken, isFetching } = useAppState();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    removeLocalAudioTrack,
    removeLocalVideoTrack
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
    });
  };

  const close = () => {
    removeLocalAudioTrack();
    removeLocalVideoTrack();
    router.push(router.routeInfo.pathname, "none", "replace");
  };

  useEffect(() => {
    return () => {
      removeLocalAudioTrack();
      removeLocalVideoTrack();
    };
  }, []);

  if (isFetching || isConnecting) {
    return (
      <Box
        variant="flexCol"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box variant="flexRow" alignItems="center" gridGap="10px">
          <H1 color="#fff" m="0">
            Joining Meeting
          </H1>
          <Spinner
            color="#fff"
            name="circles"
            transform="scale(1.5)"
            width="50px"
            height="50px"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      bg={theme.colors.whitePrx}
      width={["100%", "720px"]}
      flex={["1", "0"]}
      borderRadius={["0", "10px"]}
      m="0"
      p="0"
    >
      <Row>
        <Col p="0">
          <LocalVideoPreview identity={name} />
        </Col>
      </Row>
      <Row>
        <Col display="flex" justifyContent="center">
          <Box variant="flexRow" gridGap="7px">
            <ToggleAudioButton disabled={disableButtons} />
            <ToggleVideoButton disabled={disableButtons} />
            <SettingsMenuButton />
          </Box>
        </Col>
      </Row>

      <Row>
        <Col display="flex" justifyContent="center">
          <Button width={["100%", "210px"]} onClick={close}>
            Cancel
          </Button>
        </Col>
      </Row>
      <Row>
        <Col mb="10px" display="flex" justifyContent="center">
          <Button
            width={["100%", "210px"]}
            onClick={handleJoin}
            disabled={disableButtons}
          >
            Join Now
          </Button>
        </Col>
      </Row>
    </Grid>
  );
}
