import { Api, AxiosClient } from "@/api";
import { useAuth } from "@/store/auth/hooks";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect } from "react";
import { AppStorage } from "@/services/storage";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@/core/constants";

export const AxiosInterceptor: React.FC<any> = ({ children }) => {
  const { refreshTokens, reset } = useAuth();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response.data;
    };

    const requestInterceptor = async (config: AxiosRequestConfig) => {
      const accessToken = await AppStorage.get(ACCESS_TOKEN_KEY);
      const refreshToken = await AppStorage.get(REFRESH_TOKEN_KEY);
      let token = "";

      if (config && config.headers) {
        if (accessToken) {
          token = accessToken;
        } else if (refreshToken) {
          token = refreshToken;
        }

        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    };

    const errInterceptor = async (error: AxiosError) => {
      const errorData = error?.response?.data
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            statusCode: 400,
            message: "GENERAL_ERROR",
            details: error
          };

      if (error.response?.status === 401) {
        if (error.config.url === Api.refresh()) {
          reset();

          return Promise.reject(errorData);
        }

        try {
          await AppStorage.remove(ACCESS_TOKEN_KEY);
          await refreshTokens().unwrap();

          return await AxiosClient.request(error.config);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      return Promise.reject(errorData);
    };

    const interceptorResponse = AxiosClient.instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    const interceptorRequest =
      AxiosClient.instance.interceptors.request.use(requestInterceptor);

    return () => {
      AxiosClient.instance.interceptors.response.eject(interceptorResponse);
      AxiosClient.instance.interceptors.request.eject(interceptorRequest);
    };
  }, []);

  return children;
};
