import { AxiosClient, Api } from "@/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tokens } from "@/core/types";
import { RegistrationData } from "../types";

export const registration = createAsyncThunk(
  "auth/registration",
  (user: RegistrationData): Promise<Tokens> =>
    AxiosClient.post<Tokens>(Api.registration(), user)
);
