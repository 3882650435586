import { IonSelectOption } from "@ionic/react";
import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ButtonSelect } from "../buttonSelect";

type SelectTimePopoverProps = {
  onDateChange: (event: CustomEvent<{ value: string }>) => any;
  timeOptions: string[];
  isDisabled?: boolean;
  value?: string;
};

export const SelectTimePopover: React.FC<SelectTimePopoverProps> = ({
  onDateChange,
  isDisabled,
  value,
  timeOptions = []
}) => {
  const { t } = useTranslation();
  return (
    <ButtonSelect
      placeholder={t("INPUTS.SELECT_HOUR")}
      mode="ios"
      value={value}
      fontSize={["14px"]}
      disabled={isDisabled}
      onIonChange={onDateChange}
    >
      {timeOptions.map((option) => (
        <IonSelectOption value={option} key={option} color="primary">
          {dayjs(option).format("HH:mm")}
        </IonSelectOption>
      ))}
    </ButtonSelect>
  );
};
