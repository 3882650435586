import { getStyledElement } from "@/helpers";
import { H2 } from "../../../../h";
import { Box } from "../../../../box";
import { P } from "../../../../p";

const Subtitle = getStyledElement(H2)({
  styles: () => ({
    fontSize: "16px",
    fontWeight: "600"
  })
});

export const TermsAndConditions = () => {
  return (
    <Box>
      <Subtitle>UTILIZAREA PAGINII WEB ŞI A SERVICIILOR NOASTRE</Subtitle>
      <P>
        Termenii și condițiile se referă la utilizarea paginii noastre web,
        precum și a serviciilor prestate. Utilizând pagina noastră web sau
        serviciile noastre, sunteți de acord să respectați Termenii formulați de
        compania noastră. Vă rugăm să citiți cu atenție și în detaliu Termenii
        și condițiile. Dacă nu acceptați Termenii noștri, nu veți face nicio
        rezervare prin HeroDoc online SRL a niciunui serviciu pe care îl
        prestăm.
      </P>

      <Subtitle>DATE CU CARACTER PERSONAL</Subtitle>
      <P>
        Pentru a furniza Servicii, am putea avea nevoie să colectăm datele Dvs.
        personale. Datele cu caracter personal colectate în procesul de
        înregistrare pe site-ul nostru și în procesul de rezervare a unei
        cunsultații on-line includ: Numele, Prenumele, Data Nașterii, Nr de
        telefon/ Adresa de e-mail, datele fiscale (nr. cont bancar/ nr card)
        Aceste date (Nume, Prenume, Data Nașterii) vor fi utilizate cu scopul de
        informare a profesioniștilor individuali cu care veți avea consultații.
        Acestora li se va transmite acest set de date pentru a putea rezerva
        ziua și ora pentru consultații individuale. Nr de telefon și/ sau adresa
        de e-mail sunt folosite pentru a confirma cu dvs. rezervarea slotului
        pentru consultație, pentru a primi linkul și codul de acces la
        întîlnirea cu medicul. De asemenea, operatorii noștri vor contacta
        aleator clienții ce au avut consultații cu medicii pentru a colecta un
        feed-back și a obține păreri despre cum putem îmbunătăți serviciile
        noastre. Datele fiscale (nr cont sau card bancar) vor fi solicitate în
        procesul de achitare a serviciilor prestate de către specialiștii
        independenți. Aceste date vor fi utilizate de către băncile comerciale
        cu care dvs. și compania noastră au contracte de prestare a serviciilor
        financiare. HeroDoc online SRL își ia angajamentul ca aceste date să nu
        fie divulgate persoanelor terțe.
      </P>

      <Subtitle>TERMENI CONSUMATOR</Subtitle>
      <P>
        În baza Regulamentelor privind contractele încheiate cu consumatorii se
        prevede ca HeroDoc online SRL să înainteze către Dvs. un contract între
        Dvs. și compania noastră. Acest contract prevede faptul că sunteți de
        acord cu Termenii și Condițiile afișate pe pagina noastră web, precum și
        acțiunile întreprinse de compania noastră în cazul în care: 1. Decideți
        să nu utilizați serviciile prestate de compania noastră 2. Sunteți
        nemulțumiți de calitatea serviciilor prestate.
      </P>

      <Subtitle>CINE POATE UTILIZA SERVICIILE NOASTRE</Subtitle>
      <P>
        Serviciile noastre pot fi utilizate de oricine necesită un sfat în
        legătură cu problema sa, Pe pagina web se pot înregistra orice persoană
        rezidentă sau nerezidentă a Republicii Moldova. Pentru persoanele
        minore, înregistrarea pe site, dreptul de decizie și semnătură a
        acordurilor și contractelor se rezervă reprezentantului legal (părinte,
        tutore). Termenii noștri se aplică doar persoanelor fizice. Pentru orice
        rezervări sau achiziții din partea unor companii, contactați-ne la
        herodocinfo@gmail.com din punct de vedere juridic. Dacă aveți întrebări,
        plângeri sau cereri de rambursare, ne puteți contacta direct la HeroDoc
        online SRLinfo@gmail.com. HeroDoc online SRL va furniza, la solicitare,
        servicii de intermediere între Dvs. și un profesionist independent.
      </P>

      <Subtitle>SERVICIILE NOASTRE</Subtitle>
      <P>
        HeroDoc online SRL oferă un serviciu de rezervare și cumpărare.
        Serviciile vă permit să rezervați o serie de servicii profesionale,
        prestate în fiecare caz de către profesionişti independenți. La
        furnizarea Serviciilor, HeroDoc online SRL acționează în calitate de
        agent al profesioniștilor independenți. HeroDoc online SRL nu poartă
        nicio răspundere pentru serviciile profesionale suntem pur și simplu
        implicați în procesul de rezervare și/sau achiziție. Serviciile pe care
        le oferim vă permit să efectuați căutări prin intermediul paginii
        noastre web și să cumpărați servicii profesionale de la o serie de
        profesioniști independenți. Oferindu-vă posibilitatea de a cumpăra
        servicii profesionale prin intermediul paginii noastre web, acționăm în
        calitate de agent comercial al acelor profesioniști independenți. Cu
        toate acestea, după cum s-a menționat mai sus, contractul de
        achiziționare a serviciilor profesionale este încheiat între Dvs. şi
        profesionistul independent. Aceasta înseamnă că profesionistul
        independent este cel responsabil din punct de vedere juridic pentru
        furnizarea serviciului profesional către Dvs. Totuşi, HeroDoc online SRL
        poartă răspundere pentru obligațiile sale față de Dvs., în conformitate
        cu Termenii noștri, care sunt obligatorii din punct de vedere juridic.
        Dacă aveți întrebări, plângeri sau cereri de rambursare, ne puteți
        contacta direct la herodocinfo@gmail.com. HeroDoc online SRL va furniza,
        la solicitare, servicii de intermediere între Dvs. și un profesionist
        independent în legătură cu serviciul clienți sau rezolvarea oricăror
        litigii.
      </P>

      <Subtitle>
        CONTRACTE DE PRESTARE A SERVICIILOR DE CĂTRE PROFESIONISTUL INDIVIDUAL
      </Subtitle>
      <P>
        Atunci când sunt rezervate prin intermediul companiei noastre,
        serviciile profesionale de care beneficiați vor fi supuse termenilor și
        condițiilor profesionistului independent. Atunci când veți efectua o
        rezervare prin intermediul nostru, vi se vor furniza termenii
        profesionistului independent și va trebui să confirmați acceptarea
        acestor termeni. HeroDoc online SRL nu este parte la termenii
        profesionistului independent: acești termeni vor fi obligatorii doar
        pentru Dvs. și profesionistul independent care vă prestează serviciul
        profesional. Odată ce ați rezervat o consultație cu profesioniștii
        individuali, veți primi un contract în care vă veți da acordul să vi se
        presteze serviciile oferite de către profesioniștii individuali, care
        are aceeași putere juridică ca Acordul informat pe care îl semnați în
        cazul prestării serviciilor medicale. Alte prevederi ale acestui
        contract includ: acțiunile întreprinse în cazul în care nu vă dați
        acceptul pentru prestarea serviciilor, precum și pașii urmați în cazul
        nemulțumirilor înaintate companiei noastre sau profesioniștilor
        individuali, care se soldează cu rambursarea sumei achitate pentru
        serviciile rezervate. Dacă citind contractul înaintat spre Dvs, decideți
        să nu utilizați serviciile prestate de companie, atunci rezervarea
        efectuată în prealabil va fi anulată. În cazul în care ați rămas
        nemulțumiți de calitatea serviciilor, contactați-ne la adresa de e-mail
        herodocinfo@gmail.com explicînd motivul cin care sunteți nemulțumiți și
        cererea de ramursare a plății efectuate, atașînd confirmarea efectuării
        plății. Aceste puncte sunt obligatorii, pentru ca administratorul
        companiei, serviciul juridic și contabilitatea să cerceteze cauza și să
        acționeze în conformitate cu legislația în vigoare și prevederile
        contractuale ale părților implicate.
      </P>

      <Subtitle>
        PROCESUL DE REZERVARE ŞI ACHIZIȚIE A SERVICIILOR PROFESIONALE
      </Subtitle>
      <P>
        Puteți efectua o rezervare prin intermediul paginii web, alegând sau nu
        un profesionist independent anume sau care o sa va fie oferit de
        compania noastra în functie de disponibilitate. În momentul rezervării
        pot fi solicitate detaliile Dvs. de plată, iar plata va fi colectată
        atunci când efectuați o rezervare. Urmează să achitați integral taxa în
        momentul rezervării prin intermediul nostru a serviciului profesional
        prestat de către profesionistul independent. Detalii privind prețurile
        sunt furnizate mai jos. Taxa aparține profesionistului independent care
        prestează serviciul profesional. HeroDoc online SRL, în calitate de
        agent al profesionistului independent, colectează de la Dvs. în numele
        său taxa per serviciu prestat. Suntem desemnaţi de către profesioniști
        independenți să încheiem rezervări în numele acestora în calitate de
        agent comercial. După ce acceptăm rezervarea Dvs. în numele
        profesioniștilor independenți, va vom transmite prin e-mail sau SMS o
        confirmare privind programarea Dvs. Efectuând o rezervare, sunteți
        responsabil pentru următoarele: · Plata integrală a taxei de tratare
        aplicabile; · Decizia asumată de semnare a contractului de prestare a
        serviciilor de către profesionistul individual
      </P>

      <Subtitle>PLATĂ</Subtitle>
      <P>
        Toate taxele per serviciu se plătesc prin intermediul paginii noastre
        web, sau prin telefon. Colectăm taxele în numele profesioniştilor
        independenți. În fiecare caz, primirea plății de la Dvs. de către noi,
        în calitate de agent profesioniștilor independenți, vă eliberează de
        datoria față de profesionistul independent respectiv în valoarea sumei
        achitate. Ne angajăm ca toate datele fiscale oferite de Dvs să fie
        securizate, utilizând un mecanism de plată securizat criptat. Cu toate
        acestea, în absența neglijenței din partea HeroDoc online SRL, nu vom
        purta răspundere juridică față de Dvs. pentru orice pierdere pe care ați
        putea-o suferi dacă o terță parte obține acces neautorizat la orice
        informație pe care ne-o puteți oferi în orice moment. Toate plățile prin
        card de credit sau de debit trebuie să fie autorizate de emitentul
        acesteia. De asemenea, este posibil să recurgem la pași suplimentari de
        asigurare a securității prin Verified by Visa, după caz. Efectuând o
        rezervare sau o achiziție, sunteți de acord să furnizați informații
        complete, corecte și adevărate, inclusiv, dar fără a se limita la,
        informațiile de facturare şi de plată.
      </P>

      <Subtitle>PREȚURI SERVICII PROFESIONALE</Subtitle>
      <P>
        Prețul serviciilor profesionale variază în funcție de tipul și durata
        serviciului profesional pe care îl rezervați. Prețul per serviciu este
        stabilit periodic, iar prețul pe care urmează să-l achitați pentru un
        anumit serviciu profesional va fi determinat în funcție de prețurile în
        vigoare la data când este rezervat serviciul profesional. Detalii
        complete privind prețurile de tratare sunt prezentate pe pagina noastra
        web www.herodoc.md. Prețul serviciilor poate fi modificat în orice
        moment, dar aceste modificări nu vor afecta rezervările deja efectuate.
      </P>

      <Subtitle>RESPONSABILITĂȚILE DVS.</Subtitle>
      <P>
        - Decizia asumată de semnare a contractelor între Dvs și compania
        HeroDoc online SRL, precum și a contractului de prestare a serviciilor
        de către profesioniștii individuali. - Plata integrală pentru serviciile
        prestate. - Furnizarea informațiilor complete și exacte în momentul
        rezervării sau cumpărării. Dacă informațiile vor fi furnizate eronat,
        compania își rezervă dreptul de a nu accepta solicitarea Dvs de prestare
        a serviciilor.
      </P>

      <Subtitle>
        ANULĂRI ŞI RESTITUIRI A TAXEI PENTRU SERVICIILE PROFESIONALE
      </Subtitle>
      <P>
        Aveți dreptul contractual de a anula orice rezervare pe care ați făcut-o
        cu un profesionist independent prin intermediul nostru în următoarele
        condiții și în termenii descrişi. Dacă vă schimbați opinia cu privire la
        rezervarea făcută și anulați rezervarea prin intermediul paginii noastre
        web: • La anularea rezervării în termen de 15 minute de la confirmarea
        rezervării prin SMS sau e-mail, nu se vor percepe taxe• în termen de
        douăzeci și patru (24) de ore de la ora de programare, sau după
        scurgerea perioadei de grație de 15 minute de la confirmarea prin
        e-mail/SMS a rezervării, avem dreptul să reținem (sau să percepem, după
        caz) cincisprezece (15%) din taxa per serviciu aplicabilă, deoarece
        anularea Dvs. este una târzie. Cu toate acestea, în cazul în care
        anularea unei rezervări intervine în termen de 3 ore de la ora de
        programare și, dacă este cazul, perioada de grație s-a scurs,
        profesionistul independent are dreptul să rețină (sau să perceapă, după
        caz) o cincizeci de procente (50%) din taxa de tratare aplicabilă,
        deoarece anularea reprezintă o anulare foarte târzie.De asemenea, se va
        percepe întreaga taxă (100%) dacă:• Anulați o rezervare în timpul orei
        de programare sau după aceasta;• Nu reuşiți să vă prezentați la o
        rezervare la ora de programare. Se plătește această taxă pentru a
        compensa profesionistul independent pentru timpul efectiv pierdut în
        care acesta putea presta serviciul dat altui client. Se poate renunța la
        taxele de anulare, la discreția noastră absolută. În cazul în care
        renunţăm la taxele de anulare, acționăm ca agent al profesionistului
        independent care este principalul furnizor al serviciului profesional.
      </P>

      <Subtitle>PRELUNGIRI ŞI ÎNTÂRZIERI</Subtitle>
      <P>
        Dacă timpul pentru prestarea serviciilor de către profesionistul
        individual este depășit, acesta este în drept să solicite o taxă
        suplimentară calculată în raport cu prețul unui serviciu integral. Dacă
        Dvs întîrziați cu 10 min la consultația cu profesionistul individual, se
        solicită o înștiințare în prealabil, iar profesionistul are dreptul să
        solicite o despăgubire, calculată în raport cu prețul serviciului
        prestat, reieșind din timpul de așteptare, sau poate anula rezervarea
        Dvs, fapt despre care veți fi înștiințat.
      </P>

      <Subtitle>HeroDoc online SRL ACȚIONEAZĂ DOAR CA PREZENTATOR</Subtitle>
      <P>
        Serviciile permit celor care caută servicii profesionale să-și facă
        programări cu profesioniști independenți care doresc să ofere astfel de
        servicii profesionale. HeroDoc online SRL evaluează profesioniștii
        independenți care doresc să furnizeze servicii profesionale În
        consecință, recunoașteți și sunteți de acord că HeroDoc online SRL nu
        are nicio obligație să efectueze controale de fond în legătură cu
        oricare profesionist independent și nu are nicio obligație în ceea ce
        privește serviciile pe care le pot furniza aceştia. Când interacționați
        cu orice profesionist independent, trebuie să fiți precaut și să dați
        dovadă de bun simt pentru a vă proteja siguranța personală, informațiile
        și bunurile, la fel cum ați proceda atunci când ați interacționa cu alte
        persoane care nu vă sunt cunoscute.
      </P>

      <Subtitle>UTILIZARE INTERZISĂ</Subtitle>
      <P>
        Serviciile sunt destinate uzului personal și necomercial și trebuie
        utilizate doar pentru a solicita sau a face rezervări pentru servicii
        profesionale, după cum se descrie mai sus. Nu trebuie să utilizați
        pagina noastră web sau serviciile noastre pentru a face oricare dintre
        următoarele acțiuni (fiecare dintre acestea fiind strict interzisă):• să
        adoptați orice comportament inadecvat, inclusiv, dar fără a se limita
        la, observații ilicite sau sugestiv sexuale, avansuri sexuale;• să
        restricționați sau să împiedicați orice alt utilizator să utilizeze și
        să beneficieze de servicii; să încălcați drepturile de
        confidențialitate, drepturile de proprietate sau alte drepturi civile
        ale oricărei persoane;• să hărțuiți, să abuzați, să amenințați sau să nu
        respectați sau să încălcați drepturile profesioniștilor independenți,
        HeroDoc online SRL (inclusiv a angajaților și personalului său) sau ale
        altor persoane,• să obțineți, să căutați date sau să colectați în
        oricare alt mod informații despre alte persoane, inclusiv adrese de
        e-mail, fără consimțământul acestora; • să folosiți tehnologii sau alte
        mijloace pentru a accesa rețeaua noastră de calculatoare, conținut
        neautorizat sau spații ne-publice,• să introduceți sau să încercați să
        introduceți viruşi sau alte coduri, fişiere sau programe dăunătoare, ce
        întrerup sau limitează în alt mod funcționalitatea serviciului, paginii
        noastre web sau a aplicației noastre sau pot afecta, dezactiva sau
        împiedica în alt mod activitatea serverelor sau rețelelor noastre sau să
        încercați să faceți ceva similar; sau• să adoptați sau să încurajați pe
        alții să dea dovadă de comportamente penale sau ilegale sau să încalce
        Termenii noştri, inclusiv prin utilizarea necorespunzătoare a
        serviciilor în scopuri ilegale sau neautorizate. Sunteți de acord să nu
        încălcați Termenii noștri în niciun fel ce ar putea duce, printre
        altele, la rezilierea sau suspendarea accesului Dvs. la servicii.
      </P>

      <Subtitle>DREPTURI DE AUTOR</Subtitle>
      <P>
        Compania HeroDoc online SRL deține dreptul absolut de proprietate asupra
        însemnurilor, paginii web și gamei de servicii prestate. Acestea sunt
        înregistrate la autoritățile competente și sunt protejate prin
        legislația în vigoare cu privire la drepturile de autor.
      </P>

      <Subtitle>SPECTRUL DE RĂSPUNDERE</Subtitle>
      <P>
        După cum s-a menționat mai sus, prezentăm persoanelor în căutare de
        servicii profesionale profesionişti independenți care doresc să
        furnizeze astfel de servicii profesionale. Nu suntem responsabili sau nu
        purtăm răspundere pentru îndeplinirea oricărei rezervări sau activitatea
        profesioniştilor independenți. Conştientizați și sunteți de acord că nu
        suntem responsabili pentru soluționarea oricăror pretenții pe care le
        aveți în legătură cu orice profesionist independent sau cu orice alte
        servicii profesionale.
      </P>

      <Subtitle>TITLUL SERVICIILOR NOASTRE</Subtitle>
      <P>
        Conținutul paginii noastre web, este furnizat doar în scopuri generale
        de informare. Nu suntem un furnizor de asistență medicală și nici nu
        oferim consultanță medicală sau tratament medical. Informația obținută
        prin intermediul paginii noastre are titlu de sfat și rămîne la
        discreția Dvs să îl urmați sau nu. HeroDoc online SRL este doar un
        intermediar între Dvs și profesioniștii individuali. Conform legislației
        în vigoare HeroDoc online SRL prestează servicii de asistență tehnică.
      </P>

      <Subtitle>GARANȚII</Subtitle>
      <P>
        HeroDoc online SRL va garanta în limita legii și a capacităților
        securitatea datelor personale, canalelor de comunicare. Totuși în cazul
        implicării părților terțe, atacurilor cibernetice sau scurgerilor de
        informație de către o persoană sau către o persoană terță, HeroDoc
        online SRL se deroghează de la aceste garanții și nu va putea fi tras la
        răspundere conform legislației în vigoare. Ne rezervăm dreptul să
        suspendăm sau să încetăm periodic funcționarea integrală sau parțială a
        serviciilor pentru verificări și lucrări de mentenanță. HeroDoc online
        SRL își ia angajamentul să anunțe utilizatorii în prealabil cu privire
        la sistarea activității paginii web. În măsura maximă permisă de lege,
        nu vom purta răspundere pentru niciun fel de daune directe, indirecte,
        punitive, incidentale, speciale, consecvențiale sau de orice alt gen,
        inclusiv, fără a se limita la, daune pentru pierdere de date, venituri
        pierdute, pierderea fondului comercial, pierderea economiilor sau a
        profiturilor anticipate, sau ce rezultă din sau în orice fel legate de
        utilizarea serviciilor, sau cu întârziere sau incapacitatea de a utiliza
        serviciile, sau cu furnizarea sau incapacitatea de a furniza serviciile.
      </P>

      <Subtitle>DESPĂGUBIRE</Subtitle>
      <P>
        Sunteți de acord să ne apărați și să ne despăgubiți de orice pretenții,
        acuzații, cereri, recuperări, pierderi, daune, amenzi, penalități sau
        alte costuri sau cheltuieli de orice gen sau natură, inclusiv, dar fără
        a se limita la, taxe legale și contabile rezonabile ce apar sau se
        referă la utilizarea sau utilizarea necorespunzătoare a acestor servicii
        sau accesul la acestea și în general la încălcarea Termenilor noștri.
      </P>

      <Subtitle>MODIFICARE ŞI REZILIERE</Subtitle>
      <P>
        Putem modifica în orice moment Termenii noştri sau putem întrerupe
        utilizarea Serviciilor prin notificare. Dacă nu sunteți de acord cu
        modificările operate, trebuie să încetați utilizarea serviciilor.
      </P>

      <Subtitle>PREVEDERI LEGALE</Subtitle>
      <P>
        Sunteți de acord că Termenii noștri, în toate scopurile, vor fi
        guvernate și interpretate în conformitate cu legislația Republicii
        Moldova. De asemenea, sunteți de acord så vă supuneți jurisdicției
        exclusive a instanțelor moldovenesti în ceea ce privește orice
        revendicare sau problemă ce decurg din Termenii noștri.
      </P>
    </Box>
  );
};
