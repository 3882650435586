import { IonSelect, IonSelectOption, useIonRouter } from "@ionic/react";
import { getStyledElement, getMediaStyles } from "@/helpers";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Select = getStyledElement(IonSelect)({
  styles: ({ theme }) => ({
    color: theme.colors.whitePrx,
    backgroundColor: theme.colors.primaryPrx,
    borderRadius: "50%",
    minHeight: "0",
    ...getMediaStyles({
      fontSize: ["12px", "14px"],
      padding: ["9px", "10px"]
    }),
    "&::part(icon)": {
      display: "none !important"
    },
    "&::part(container)": {
      lineHeight: 1
    }
  })
});

export const LanguageToggle: React.FC = () => {
  const router = useIonRouter();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (event: CustomEvent<{ value: string }>) => {
    setLanguage(event.detail.value);
    router.push(
      `${event.detail.value}/${router.routeInfo.pathname.replace(
        /^.{4}/g,
        ""
      )}`,
      "none",
      "replace"
    );
  };

  return (
    <Select
      onIonChange={changeLanguage}
      interface="popover"
      placeholder="Language"
      value={language}
    >
      <IonSelectOption value="en">En</IonSelectOption>
      <IonSelectOption value="ro">Ro</IonSelectOption>
      <IonSelectOption value="ru">Ru</IonSelectOption>
    </Select>
  );
};
