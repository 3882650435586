import { Api } from "@/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { GetAppointmentsParams } from "@/core/types/api/getAppointmentsParams";
import { UpdateAppointment } from "@/core/types/api/updateAppointment";
import { RefundPayment } from "@/core/types/api/refundPayment";
import { Appointment, AppointmentFull, CreateAppointment } from "./types";
import { axiosBaseQuery } from "../baseQuary";

export const appointmentsApi = createApi({
  reducerPath: "Appointments",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Appointments"],
  endpoints: (build) => ({
    getMeAppointments: build.query<Appointment[], void>({
      query: () => ({
        url: Api.meAppointments(),
        method: "get"
      }),
      providesTags: () => [{ type: "Appointments", id: "LIST" }]
    }),
    getAppointment: build.query<AppointmentFull, number>({
      query: (id) => ({
        url: Api.appointment(id),
        method: "get"
      }),
      providesTags: (_, __, id) => [{ type: "Appointments", id }]
    }),
    getAppointments: build.query<Appointment[], GetAppointmentsParams>({
      query: (params: GetAppointmentsParams) => ({
        url: Api.appointments(),
        method: "get",
        params
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset === 0) return newItems;

        return [...currentCache, ...newItems];
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      providesTags: () => {
        return [{ type: "Appointments", id: "LIST" }];
      }
    }),
    addAppointment: build.mutation<Appointment, CreateAppointment>({
      query: (body) => ({
        url: Api.appointments(),
        method: "POST",
        data: body
      }),
      invalidatesTags: [{ type: "Appointments", id: "LIST" }]
    }),
    finishAppointment: build.mutation<Appointment, number>({
      query: (id) => ({
        url: Api.appointmentFinish(id),
        method: "PUT"
      }),
      invalidatesTags: [{ type: "Appointments", id: "LIST" }]
    }),
    deleteAppointment: build.mutation<void, number>({
      query: (id) => ({
        url: Api.appointment(id),
        method: "DELETE"
      }),
      invalidatesTags: (_, __, id) => [
        { type: "Appointments", id },
        { type: "Appointments", id: "LIST" }
      ]
    }),
    refundPayment: build.mutation<void, RefundPayment>({
      query: ({ appointmentId, ...body }) => ({
        url: Api.paymentRefund(),
        method: "POST",
        data: body
      }),
      invalidatesTags: (_, __, { appointmentId }) => [
        { type: "Appointments", id: appointmentId },
        { type: "Appointments", id: "LIST" }
      ]
    }),
    updateAppointment: build.mutation<void, UpdateAppointment>({
      query: ({ id, ...body }) => ({
        url: Api.appointment(id),
        method: "PUT",
        data: body
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: "Appointments", id },
        { type: "Appointments", id: "LIST" }
      ]
    })
  })
});
