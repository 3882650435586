import { useUpdateAppointmentMutation } from "@/store/appointments/hooks";
import { useEffect } from "react";
import { useHttpErrorHandler } from "./useHttpErrorHandler";
import { useToast } from "./useToast";

export const useUpdateAppointment = () => {
  const [updateAppointment, { error, isSuccess }] =
    useUpdateAppointmentMutation();
  const { showSuccess } = useToast();

  useHttpErrorHandler(error);

  useEffect(() => {
    if (!isSuccess) return;

    showSuccess({ message: "TOASTS.SAVED_DATA" });
  }, [isSuccess]);

  return { updateAppointment };
};
