import { appointmentsApi } from "../api";

export const {
  useGetMeAppointmentsQuery,
  useAddAppointmentMutation,
  useFinishAppointmentMutation,
  useGetAppointmentsQuery,
  useGetAppointmentQuery,
  useUpdateAppointmentMutation,
  useRefundPaymentMutation,
  useDeleteAppointmentMutation
} = appointmentsApi;
