import { SpinnerContext } from "@/contexts";
import { useDoctorCalendar, useMedia } from "@/hooks";
import { FC } from "react";
import { Box } from "../box";
import { Calendar } from "../calendar";
import { SelectTimePopover } from "../selectTimePopover";
import { SelectTimeAccordion } from "../selectTimeAccordion";

export const ScheduleCalendar: FC<{
  doctorId: number;
  handleDismiss: (data: string) => void;
}> = ({ doctorId, handleDismiss }) => {
  const {
    timeOptions,
    min,
    onDateChange,
    onMonthChange,
    isDateEnabled,
    isLoading
  } = useDoctorCalendar(doctorId);
  const { isMobile } = useMedia();

  return (
    <SpinnerContext.Provider value={isLoading}>
      <Box display="flex" alignItems="center" justifyContent="center" p="10px">
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          alignItems={["center", "flex-start"]}
        >
          <Calendar
            onDateChange={onDateChange}
            onMonthChange={onMonthChange}
            isDateEnabled={isDateEnabled}
            yearDisabled
            min={min}
          />
          <Box variant="flexCol" mx="10px">
            {isMobile ? (
              <SelectTimePopover
                isDisabled={!timeOptions.length}
                onDateChange={(event) => {
                  handleDismiss(event.detail.value);
                }}
                timeOptions={timeOptions}
              />
            ) : (
              <SelectTimeAccordion
                isDisabled={!timeOptions.length}
                onDateChange={(event) => {
                  handleDismiss(event.detail.value);
                }}
                timeOptions={timeOptions}
              />
            )}
          </Box>
        </Box>
      </Box>
    </SpinnerContext.Provider>
  );
};
