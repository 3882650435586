import React from "react";
import Video from "twilio-video";
import { Box, LinkTo } from "@/components";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from "@ionic/react";
import { getStyledElement } from "@/helpers";
import { useHistory } from "react-router";

const CardTitle = getStyledElement(IonCardTitle)({
  styles: () => ({
    fontSize: "32px"
  })
});

export default function UnsupportedBrowserWarning({
  children
}: {
  children: React.ReactElement;
}) {
  const history = useHistory();

  if (!Video.isSupported) {
    return (
      <Box variant="flexRow" justifyContent="center" marginTop="2.5em">
        <IonCard>
          <IonCardHeader>
            <CardTitle>Browser or context not supported</CardTitle>
            <IonCardSubtitle>
              Please open this application in one of the{" "}
              <LinkTo
                href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                target="_blank"
                rel="noopener"
              >
                supported browsers
              </LinkTo>
              <br />
              If you are using a supported browser, please ensure that this app
              is served over a{" "}
              <LinkTo
                href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
                target="_blank"
                rel="noopener"
              >
                secure context
              </LinkTo>{" "}
              (e.g. https or localhost).
              <br />
              <br />
              <LinkTo onClick={() => history.goBack()}>Go Back</LinkTo>
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      </Box>
    );
  }

  return children;
}
