/* eslint-disable prefer-destructuring */
import { Theme } from "styled-components";

type BreakpointsProp = Array<string> & {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

const breakpoints: BreakpointsProp = ["768px", "768px", "992px", "1200px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const theme: Theme = {
  breakpoints,
  colors: {
    primaryPrx: "#2997E7",
    secondaryPrx: "#45BF93",
    ternaryPrx: "#DF2935",
    tangerine: "#FF934F",
    darkPrx: "#25282E",
    lightPrx: "#FFFFFF",
    grayPrx: "#7C7E82",
    whiteBluePrx: "#A8E0F2",
    greenPrx: "#45BF93",
    whitePrx: "#ffffff",
    grayF4Prx: "#E9F2F4",
    grayC1Prx: "#C1C1C1",
    gray9DPrx: "#8D919D",
    grayF6Prx: "#F3F4F6",
    gray9CPrx: "#92949c",
    blueC5Prx: "#70B0C5",
    red74Prx: "#F26674",
    black2BPrx: "#282a2b"
  },
  sizes: {
    cartBorderRadius: "15px",
    buttonBorderRadius: "25px",
    buttonPadding: "10px",
    headerHeight: "68px",
    doctorListImageHeight: ["100px", "120px"],
    videoMenuHeight: "63px",
    calendarHeight: "405px",
    inputHeight: "48px"
  },
  fontSize: {
    12: "12px",
    14: "14px",
    16: "16px",
    20: "20px",
    45: "45px"
  },
  shadow: {
    dark: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    neutral: "0px 3px 4px 0px #C1C1C1"
  },
  padding: {
    5: "5px",
    6: "6px",
    10: "10px",
    12: "12px",
    15: "15px",
    20: "20px"
  },
  borderRadius: {
    10: "10px",
    15: "15px",
    20: "20px",
    30: "30px"
  }
};
